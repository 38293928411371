// ***********************************************************
// * MHRL Project : Javascript Timing Routines               *
// ***********************************************************


// timeAgo('2011-09-13T11:28:19+0000');

function timeAgo(date_time, reference_date) {                   
                //to get unix timestamp
//                var currentDate = Math.round(+new Date()/1000);
                var tweetDate = Math.round(+new Date(date_time)/1000); 
                //alert(tweetDate);
                var diffTime = reference_date - tweetDate;
                //alert(diffTime);
                if (diffTime < 59) return 'less than a minute ago';
                else if(diffTime > 59 && diffTime < 120) return 'about a minute ago';
                else if(diffTime >= 121 && diffTime <= 3600) return (parseInt(diffTime / 60)).toString() + ' minutes ago';
                else if(diffTime > 3600 && diffTime < 7200) return '1 hour ago';
                else if(diffTime > 7200 && diffTime < 86400) return (parseInt(diffTime / 3600)).toString() + ' hours ago';
                else if(diffTime > 86400 && diffTime < 172800) return '1 day ago';
                else if(diffTime > 172800 && diffTime < 604800) return (parseInt(diffTime / 86400)).toString() + ' days ago';
                else if(diffTime > 604800 && diffTime < 12089600) return '1 week ago';
                else if(diffTime > 12089600 && diffTime < 2630880) return (parseInt(diffTime / 604800)).toString() + ' weeks ago';
                else if(diffTime > 2630880 && diffTime < 5261760) return '1 month ago';
                else if(diffTime > 5261760 && diffTime < 31570560) return (parseInt(diffTime / 2630880)).toString() + ' months ago';
                else if(diffTime > 31570560 && diffTime < 63141120) return '1 year ago';
                else return (parseInt(diffTime / 31570560)).toString() + ' years ago';
            }
