// ***********************************************************
// * MHRL Project : Javascript general routines              *
// ***********************************************************

//String.prototype.trim = function() { return this.replace(/^\s+|\s+$/g, ''); };

var BAR_DELIMITER = "|";
var ANGLE_BRACKET_LEFT = "(";
var ANGLE_BRACKET_RIGHT = ")";
var QCOOKIE_DO_NOT_EXPIRE = new Date(2099).toDateString;
var COOKIE_DO_NOT_EXPIRE = { expires: 315360000000 };
var sEntityFave, iEntityLastSelect, sUnits;
var COOKIE_ENTITY_FAVE = "EntityFaves";
var COOKIE_GL_OFF = "GLOff";
var COOKIE_MAP_TYPE = "MapType";
var COOKIE_UNITS = "Units";
var COOKIE_COOKIE_NOTE = "NoteCookies";
var COOKIE_GENERAL_NOTE = "NoteGen";
var COOKIE_WE_USE_COOKIES_NOTE = "NoteCookies";
var COOKIE_DG = "DGph";
var COOKIE_ENTITY_LAST_SELECT = "EntityLastSelect";
var COOKIE_ENTITY_DEBUG_ON = "Debug";
var COOKIE_DEFAULT_CENTRE_LAT = "DefaultCentreLat";
var COOKIE_DEFAULT_CENTRE_LNG = "DefaultCentreLng";
var COOKIE_DEFAULT_ZOOM = "DefaultZoom";
var iCurrentEntity;
var iCurrentGraph;
var bEntitiesOn = true;
var bRSMarkersOn = true;
var iDefaultZoomLevel = 2;

// Stats panel constants
var Consts =
    {
        MetricImpFactor: 3.28084,
        sMAP_TYPE_GOOGLE: "Goo",
        sMAP_TYPE_OS: "OS",
        sENTITY_TAG: "#rli",
        sFAV_ENTITY_TAG: "#fav_rli",
        sENTITY_SEARCH_START_MS: 500,
        sENTITY_HIGHLIGHT_TIME_MS: 1000,
        sPOLY_COLOUR_WITH_FOCUS: "#222222",
        sIMG_ROOT: "images/markers/",
        HL_On: "#AAAAAA",
        HL_Off: "#FFFFFF",
        EntityNumOffset: 3,
        NotDefined: -1,
        READING_DECIMALS: 2,
        LCD: 16,
        sFEET_SINGLE_INDICATOR_WORDS: " foot",
        sINCHES_SINGLE_INDICATOR_WORDS: " inch",
        sFEET_PLURAL_INDICATOR_WORDS: " feet",
        sINCHES_PLURAL_INDICATOR_WORDS: " inches",
        sFEET_SINGLE_INDICATOR_SYMBOLS: "'",
        sINCHES_SINGLE_INDICATOR_SYMBOLS: '"',
        sFEET_PLURAL_INDICATOR_SYMBOLS: "'",
        sINCHES_PLURAL_INDICATOR_SYMBOLS: '"',
        bREADING_IN_WORDS: false,
        sUNIT_CODE_METRIC: "M",
        sUNIT_CODE_IMPERIAL: "I",
        sUNIT_CODE_SWITCH_TO_METRIC: "Switch to Metric",
        sUNIT_CODE_SWITCH_TO_IMPERIAL: "Switch to Imperial",
        sTO_UNIT_CODE: "F",
        sDELIMITER_BAR: "|",
        sSPACE: " ",
        iRIVER_LINK_LEN: "#rli".length,
        sCLASS_ROOT_SPATE_LEVEL: "spate_level_",
        sSTATE_TREND_RISING_CODE: "R",
        sSTATE_TREND_FALLING_CODE: "F",
        sSTATE_TREND_STEADY_CODE: "S",
        sSTATE_TREND_CHANGING_CODE: "S",
        sSTATE_TREND_NOT_AVAILABLE: "NA",
        sSTATE_TREND_RISING_DESCRIPTION: "rising",
        sSTATE_TREND_FALLING_DESCRIPTION: "falling",
        sSTATE_TREND_STEADY_DESCRIPTION: "steady",
        sSTATE_TREND_CHANGING_DESCRIPTION: "changing",
        sSTATE_TREND_UNKNOWN_DESCRIPTION: "unknown",
        //    sSTATE_TREND_RISING_IMAGE:"levelrising.png",
        //    sSTATE_TREND_FALLING_IMAGE:"levelfalling.png",
        //    sSTATE_TREND_STEADY_IMAGE:"levelsteady.png",
        //    sSTATE_TREND_UNKNOWN_IMAGE:"levelunknown.png",

        sSTATE_LEVEL_ABOVE_CODE: "A",
        sSTATE_LEVEL_BELOW_CODE: "B",
        sSTATE_LEVEL_NORMAL_CODE: "N",
        sSTATE_LEVEL_ABOVE_DESCRIPTION: "aboveTEST",
        sSTATE_LEVEL_BELOW_DESCRIPTION: "belowTEST",
        sSTATE_LEVEL_NORMAL_DESCRIPTION: "normalTEST",
        sSTATE_LEVEL_UNKNOWN_DESCRIPTION: "unknownTEST",
        sFILE_TYPE_PNG: ".png"
        //    sSTATE_LEVEL_ABOVE_IMAGE:"levelabove.png",
        //    sSTATE_LEVEL_BELOW_IMAGE:"levelbelow.png",
        //    sSTATE_LEVEL_NORMAL_IMAGE:"levelnormal.png",
        //    sSTATE_LEVEL_UNKNOWN_IMAGE:"levelunknown.png",
    }
var linesLayer, vectorLayer, markerLayer, points, lineFeature, lineString, routeMarkersLayer, routepos, routesize, routeoffset, routeicon;

var arrEntityMarker = new Array();      // array of Entity Markers
var arrRSMarker = new Array();          // array of Reading Station Markers
var arrEntityLatLng = new Array();      // array of Entity Marker Coordinates
var arrRSLatLng = new Array();          // array of Reading Station Marker Coordinates
var arrPolyLatLngPoints = new Array();  // array of individual Polyline points
var arrPolyLines = new Array();         // array of Polylines
var arrPolyFeature = new Array();       // array of OS Features for Polylines
var arrEntityImages = new Array();      // array of Plain and Focus images for Entity Marker mouseovers
var arrEntityRSImages = new Array();    // array of Plain and Focus images for Entity RS Marker mouseovers
var iPrevEntityNum = Consts.NotDefined; // Entity number of last entity mouse overed.
var iTimerEntityHighlight;              // Timer ID returned from setTimeout for returning Entity elements
// to normal video.
var iTimerRSHighlight;                  // Timer ID returned from setTimeout for returning RS Markers
// to normal video.
var iEntityHighlightOff = "";           // Function call that should be made to turn off the highlight on 
// current set of Entity elements
var iPrevRSNum = Consts.NotDefined;     // Reading Station number of last entity mouse overed.
var sReferenceData, sDynamicData;
var mapLatlng;                          // Latlng location of the main page's map

var map, mapOptions;                    // Map and Map Option settings for the main page's map
var layerListeners, Entitystyle, RSstyle, Polystyle, EntityLayer, selEntityLayer, RSLayer, VectorLayer;
//var layerListeners, Entitystyle, RSstyle, Polystyle, EntityLayer, selEntityLayer, RSLayer;

// Miscellaneous others
function GetFocusChar(Focus) {
    // Return a string denoting whether the given Focus parameter is True or False
    // i.e. "FOCUS" or "PLAIN"

    if (Focus) {
        return "FOCUS";
    }
    else {
        return "PLAIN";
    }
}

function GetEntityLevelTrendImageName(EMnemonic, Focus, GraphicPerEntity) {
    // Return the Entity Marker Image name that corresponds to the given Overall Above/Below State, 
    // Overall Rise/Fall State and OverallStateColourVal
    // e.g. Below/Rising/1, Below/Steady/1, Below/Falling/1, Below/Change/1, ... ../../2..., ...
    // e.g. Above/Rising/1, Above/Steady/1, Above/Falling/1, Above/Change/1, ... ../../2..., ...
    // e.g. Normal/Rising/1, Normal/Steady/1, Normal/Falling/1, Normal/Change/1, ... ../../2..., ...
    // Focus denotes whether this image is to be used for Mouseover (focus) or plain display

    // For OpenSpace, use the same graphic throughout and annotate as necessary (if possible)

    if (GraphicPerEntity == true) {
//        return "images/markers/EMkr" + EMnemonic + GetFocusChar(Focus) + ".gif";
        return "http://maps.google.com/mapfiles/kml/paddle/blu-blank.png";
    }
    else {
        // $^$
//        return "images/markers/EMkr" + EMnemonic + GetFocusChar(Focus) + ".gif";
        return "http://maps.google.com/mapfiles/kml/paddle/grn-blank.png";
    }
}

function GetRSLevelTrendImageName(RSIndex, Focus) {
    // Return the Reading Station Marker Image name e.g. RSMkr3PLAIN.png or RSMkr3FOCUS.png
    // Focus denotes whether this image is to be used for plain display, or when mouseovered

    return "images/markers/RSMkr" + (RSIndex + 1) + GetFocusChar(Focus) + ".png";
}

function ConvertColourNum(ColourCode) {
    // Return the hashed RGB colour string relating to the given colour code (1-7 ish)

    var ReturnCode;

    switch (ColourCode) {
        case 1: ReturnCode = "#7A7A7A";
            break;
        case 2: ReturnCode = "#99FFFF";
            break;
        case 3: ReturnCode = "#00FF00";
            break;
        case 4: ReturnCode = "#FFFF00";
            break;
        case 5: ReturnCode = "#EB00F6";
            break;
        case 6: ReturnCode = "#FF6600";
            break;
        case 7: ReturnCode = "#FF0000";
            break;
        //             default: ReturnCode = "#4C0099";   // Default is black if no "valid" colour code given
        default: ReturnCode = "#000000";   // Default is black if no "valid" colour code given

            //        default : ReturnCode = "#333333";   // Default is black if no "valid" colour code given
            break;

        //        case 1 : ReturnCode = "#7A7A7A";
        //                 break;
        //        case 2 : ReturnCode = "#92ABE6";
        //                 break;
        //        case 3 : ReturnCode = "#1DFF00";
        //                 break;
        //        case 4 : ReturnCode = "#FFFF00";
        //                 break;
        //        case 5 : ReturnCode = "#FF00FF";
        //                 break;
        //        case 6 : ReturnCode = "#FF6600";
        //                 break;
        //        case 7 : ReturnCode = "#FF0000";
        //                 break;
        //        default : ReturnCode = "#333333";   // Default is black if no "valid" colour code given
        //                 break;

    }
    //console.log ("Poly Colour will be " + ReturnCode);
    return ReturnCode;

}

function StringExtractDelimitedSubstring(TargetString, LeftDelim, RightDelim) {
    // Extract a substring from the given string where it is delimited by the given left 
    // and right delimiter characters

    var LeftOffset, RightOffset;
    var ReturnString = "";

    // Get the index of the left delimiter        
    try {
        LeftOffset = TargetString.indexOf(LeftDelim);
        if (LeftOffset != -1) {
            // Take the substring of the string from that left delimiter
            ReturnString = TargetString.substring(LeftOffset);
            RightOffset = ReturnString.indexOf(RightDelim);
            if (RightOffset != -1) {
                ReturnString = ReturnString.slice(1, RightOffset);
            }
        }
    }
    catch (exception) {
        //alert (exception.description)
    }

    finally {
        return ReturnString;
    }

}

// ^^ Cookie Routines

// ******************************************
// ****** Add a value to Cookie string ******
// ******************************************
function CookieAddValue(CookieName, CookieString, TheValue) {
    // Add the given value as a "|" delimited item to the given existing Cookie string 
    // and save the cookie name/value pair
    // Return the new Cookie string

    if ((CookieString == null) || (CookieString == undefined)) {
        CookieString = "";
    }

    if (CookieString.indexOf(TheValue) == -1) {
        console.log("Adding Cookie Value to Name : ", TheValue, "/", CookieName);
        if (CookieString.length == 0) {
            CookieString = BAR_DELIMITER + TheValue + BAR_DELIMITER;
        }
        else {
            CookieString += TheValue + BAR_DELIMITER;
        }

        $.cookie(CookieName, CookieString.toString(), COOKIE_DO_NOT_EXPIRE);
    }
    return CookieString;
}

// ***********************************************************
// ****** Remove a delimited value from a Cookie string ******
// ***********************************************************
function CookieRemoveValue(CookieName, CookieString, TheValue) {
    // Remove the given "|" delimited value from the given existing Cookie string 
    // Return the new Cookie string

    console.log("Removing Cookie Value from Name : ", TheValue, "/", CookieName);
    console.log("Cookie String was : ", CookieString);
    CookieString = CookieString.replace(TheValue + BAR_DELIMITER, "");
    console.log("Cookie String now : ", CookieString);
    $.cookie(CookieName, CookieString.toString(), COOKIE_DO_NOT_EXPIRE);
    return CookieString;
}


function StringContainsDelimitedValue(theString, TheValue) {
    // Look for the given value together with leading delimiter and return True/False

    try {
        if (theString.indexOf(TheValue + BAR_DELIMITER) == -1) {
            return false;
        }
        else {
            return true;
        }
    }
    // If the value was null, then an exception will be thrown by the indexOf call
    // Return false since the string won't exist in this case
    catch (exception) {
        return false;
    }
}


// ***********************************************************
// * Determine whether the given CookieName contains  the    *
// * given string using the default delimiter "|"            *
// ***********************************************************
function CookieContainsDelimitedValue(CookieName, TheValue) {
    // Look for the given value together with leading delimiter and return True/False

    try {
        var theString = $.cookie(CookieName);
        return StringContainsDelimitedValue(theString, TheValue);
        if (theString.indexOf(BAR_DELIMITER + TheValue + BAR_DELIMITER) == -1) {
            return false;
        }
        else {
            return true;
        }
    }
    // If the value was null, then an exception will be thrown by the indexOf call
    // Return false since the string won't exist in this case
    catch (exception) {
        return false;
    }
}



function GetEntityRSCookieName(Entity, ReadingStation) {
    return "$" + Entity + "$" + ReadingStation;
}

// ************************************************************
// * Return  the next element within a given string with the  *
// * given delimiter. The routine expects delimiters to the   *
// * left of elements.                                        *
// * If an empty string has been passed, return the empty     *
// * string                                                   *
// ************************************************************
function GetNextDelimitedValue(theString, delimiter) {
    // Look for the given value together with leading delimiter and return True/False

    var iOffset;

    if (theString.length == 0) {
        return ("");
    }
    else {
        try {
            iOffset = theString.indexOf(delimiter);
            if (iOffset == -1) {
                // No more delimiters so return the string passed as the final element
                return (theString);
            }
            else {
                return (theString.substr(0, iOffset));
            }
        }
        // If the value was null, then an exception will be thrown by the indexOf call
        // Return the null string since the string won't exist in this case
        catch (exception) {
            return "";
        }
    }
}

function GetTrendState(TrendCode) {
    // From the given Trend Code, return a string denoting the level state

    switch (TrendCode) {
        case Consts.sSTATE_TREND_RISING_CODE:
            return "<span class=stateRising>" + Consts.sSTATE_TREND_RISING_DESCRIPTION + "</span>";
            break;
        case Consts.sSTATE_TREND_FALLING_CODE:
            return "<span class=stateFalling>" + Consts.sSTATE_TREND_FALLING_DESCRIPTION + "</span>";
            break;
        case Consts.sSTATE_TREND_STEADY_CODE:
            return "<span class=stateSteady>" + Consts.sSTATE_TREND_STEADY_DESCRIPTION + "</span>";
            break;
        case Consts.sSTATE_TREND_CHANGING_CODE:
            return "<span class=stateChanging>" + Consts.sSTATE_TREND_CHANGING_DESCRIPTION + "</span>";
            break;
        default:
            return Consts.sSTATE_TREND_UNKNOWN_DESCRIPTION;
            break;
    }
}

function GetLevelState(LevelCode) {
    // From the given Level Code, return a string denoting the level state

    switch (LevelCode) {
        case Consts.sSTATE_LEVEL_ABOVE_CODE:
            return "above";
            break;
        case Consts.sSTATE_LEVEL_BELOW_CODE:
            return "below";
            break;
        case Consts.sSTATE_LEVEL_NORMAL_CODE:
            return "normal";
            break;
        default:
            return "??????";
            break;
    }
}

function GetLevelDetails(SourceLevel, SourceLevelImp, SourceLevelPC, RiseFallState,
    StateColourVal, ToUnitCode) {
    // From the given level detail, return an array containing :-
    // Output level in the requested "ToUnitCode". e.g. 1.2 metres or 3 feet 4 inches. This is taken directly
    // from the relevant Dynamic JSON data structure element (Level or LevelImp for metres, imperial respectively
    // The trend. e.g. "rising"
    // the state graphics file identifier (made up of StateColourVal and trend)

    // * Note : Level and DatumLevel will already be rounded to the required number of decimal places
    // ^^ DatumLevel left in for now in case we add a relative level above/below normal to the output at some point
    // ^^ UnitCode not currently used. Note if units other than Metres and Feet and Inches are used, further
    // work will be necessary.

    var sStateImg, sStateTrend;

    var arrReturnArray = [];

    //console.log ("SourceLevel [", SourceLevel, "]");

    // arrReturnArray.push(Math.min((SourceLevel / 5 * 100), 100));
    arrReturnArray.push(SourceLevelPC);

    // Work out the state
    sStateTrend = GetTrendState(RiseFallState);

    // Load all return elements into an array so that they POP in the following order :-
    // Relative Level, Absolute Level, LevelImg, TrendImg, LevelDesc, TrendDesc

    arrReturnArray.push(sStateTrend);

    if (RiseFallState == Consts.sSTATE_TREND_NOT_AVAILABLE) {
        arrReturnArray.push('');
    } else {
        // If the reading is not available, push the empty string, otherwise push "<level> <units> and "
        // This is then rendered as "<level> <units> and rising" (for instance) or "unknown"
        if (ToUnitCode == Consts.sUNIT_CODE_METRIC) {
            arrReturnArray.push(SourceLevel + Consts.sUNIT_CODE_METRIC.toLowerCase() + ' and ');
        } else {
            arrReturnArray.push(SourceLevelImp + ' and ');
        }
    }
    // and the appropriate image
    if (RiseFallState == Consts.sSTATE_TREND_NOT_AVAILABLE) {
        sStateImg = "state0" + Consts.sFILE_TYPE_PNG;
    } else {
        sStateImg = "state" + StateColourVal + RiseFallState + Consts.sFILE_TYPE_PNG;
    }
    arrReturnArray.push(sStateImg);

    return arrReturnArray;

}

function EntityMarkerToggle() {
    // Toggle all Entity Markers On/Off depending on their current state

    //console.log ('Entity Marker Toggle hit');
    for (var i = 0, ilimit = arrEntityImages.length; i < ilimit; i++) {
        // Hide or display Adjust the Entity marker image to highlighted/non highlighted
        if (sReferenceData[i]['__MO'] == 1) {
            if (GOOGLE_ON == 1) {
                if (bEntitiesOn == true) {
                    arrEntityMarker[i].setOptions({ visible: false });
                    //console.log ('Hiding Entity Marker ', i);
                }
                else {
                    arrEntityMarker[i].setOptions({ visible: true });
                    //console.log ('Showing Entity Marker ', i);
                }
            }
            else if (OS_ON == 1) {
                if (bEntitiesOn == true) {
                    arrEntityMarker[i].display(false);
                    //console.log ('Hiding Entity Marker ', i);
                }
                else {
                    arrEntityMarker[i].display(true);
                    //console.log ('Showing Entity Marker ', i);
                }
            }

        }
    }
    bEntitiesOn = !bEntitiesOn;
}


function RSMarkerToggle() {
    // Toggle all RS Markers On/Off depending on their current state

    //console.log ('RS Marker Toggle hit');
    for (var i = 0, ilimit = arrEntityImages.length; i < ilimit; i++) {
        if (sReferenceData[i]['__MO'] == 1) {
            for (var j = 0, jlimit = arrEntityRSImages[i].length; j < jlimit; j++) {
                // Hide or display Adjust the Entity marker image to highlighted/non highlighted
                if (bRSMarkersOn == true) {
                    if (GOOGLE_ON == 1) {
                        arrRSMarker[i][j].setOptions({ visible: false });
                        //console.log ('Hiding RS Marker ', i, j);
                    }
                    else if (OS_ON == 1) {
                        arrRSMarker[i][j].display(false);
                        //console.log ('Hiding RS Marker ', i, j);
                    }
                }
                else {
                    if (GOOGLE_ON == 1) {
                        arrRSMarker[i][j].setOptions({ visible: true });
                        //console.log ('Showing RS Marker ', i, j);
                    }
                    else if (OS_ON == 1) {
                        arrRSMarker[i][j].display(true);
                    }
                }
            }
        }
    }
    bRSMarkersOn = !bRSMarkersOn;
}

function SetMapCentreAndZoom(UseMap, UseMapCentreLat, UseMapCentreLng, UseMapZoom, SetOptionsOnly) {
    // Set the map center and zoom level to its configured default

    var mapOptions = new Object();
    var mapLatlng;

    if (MAPS_ON == 1) {
        console.log("Moving map to Centre / Zoom ", UseMapCentreLat, UseMapCentreLng, UseMapZoom);
        //+_+
        if (GOOGLE_ON == 1) {
            mapLatlng = new google.maps.LatLng(UseMapCentreLat, UseMapCentreLng);

            mapOptions['center'] = mapLatlng;
            mapOptions['zoom'] = UseMapZoom;

            if (SetOptionsOnly == false) {
                map.setOptions(mapOptions);
            }
        }
        else if (OS_ON == 1) {
            mapLatlng = new OpenLayers.LonLat(parseInt(UseMapCentreLng), parseInt(UseMapCentreLat));

            if (SetOptionsOnly == false) {
                //                    map.setCenter(mapLatlng);
                map.setCenter(mapLatlng, UseMapZoom);
            }
        }
    }
}

function SaveMapCentreAndZoom() {
    // Derive the center and zoom level of the map as it currently stands and save it to a cookie.

    var mapCentre;
    var mapZoom;
    var lat;
    var lng;

    if (GOOGLE_ON === 1) {
        mapCentre = map.getCenter();
        mapZoom = map.getZoom();
        lat = mapCentre.lat();
        lng = mapCentre.lng();
    }
    else if (OS_ON == 1) {
        mapCentre = map.getCenter();
        mapZoom = map.getZoom();
        lat = mapCentre.lat;
        lng = mapCentre.lon;
    }

    //        console.log ("Setting map up as Centre / Zoom ", mapCentre, mapZoom);
    //        console.log ("Lat is  ", mapCentre.lat());
    //        console.log ("Lng is  ", mapCentre.lng());

    var CookieDebug = $.cookie(COOKIE_ENTITY_DEBUG_ON);
    //$$^^
    //        if (CookieDebug.length != 0) {
    //            alert(mapCentre.lat() + ", " + mapCentre.lng() + ", " + mapZoom);
    //        }
    $.cookie(COOKIE_DEFAULT_CENTRE_LAT, lat.toString(), COOKIE_DO_NOT_EXPIRE);
    $.cookie(COOKIE_DEFAULT_CENTRE_LNG, lng.toString(), COOKIE_DO_NOT_EXPIRE);
    $.cookie(COOKIE_DEFAULT_ZOOM, mapZoom.toString(), COOKIE_DO_NOT_EXPIRE);
}

function SetMapCentreAndZoomToDefault(SetOptionsOnly) {
    // Retrieve the default map centre and zoom settings from its cookie and call SetMapCentreAndZoomToDefault
    // to put in in place

    var defaultZoom;

    console.log("Setting map to default centre & zoom");

    var DefMapCentreLat = $.cookie(COOKIE_DEFAULT_CENTRE_LAT);
    var mapType = $.cookie(COOKIE_MAP_TYPE);

    // If either the centre is undefined, or we're using OS Maps and the default looks like its
    // beeing previously running on Latitude/Longitude instead of Northing/Easting, default it
    if ((DefMapCentreLat == null) ||
        ((OS_ON == 1) && (mapType != Consts.sMAP_TYPE_OS)) || ((GOOGLE_ON == 1) && (mapType != Consts.sMAP_TYPE_GOOGLE))) {
        if (GOOGLE_ON == 1) {
            $.cookie(COOKIE_DEFAULT_CENTRE_LAT, "56.6381058".toString(), COOKIE_DO_NOT_EXPIRE);
            $.cookie(COOKIE_DEFAULT_CENTRE_LNG, "-3.7902828".toString(), COOKIE_DO_NOT_EXPIRE);
            defaultZoom = "7";
            $.cookie(COOKIE_MAP_TYPE, Consts.sMAP_TYPE_GOOGLE, COOKIE_DO_NOT_EXPIRE);
        }
        else if (OS_ON == 1) {
            $.cookie(COOKIE_DEFAULT_CENTRE_LAT, "323386".toString(), COOKIE_DO_NOT_EXPIRE);
            $.cookie(COOKIE_DEFAULT_CENTRE_LNG, "401324".toString(), COOKIE_DO_NOT_EXPIRE);
            //                $.cookie(COOKIE_DEFAULT_CENTRE_LAT, "56.6381058".toString(), COOKIE_DO_NOT_EXPIRE);
            //                $.cookie(COOKIE_DEFAULT_CENTRE_LNG, "-3.7902828".toString(), COOKIE_DO_NOT_EXPIRE);
            defaultZoom = "1";
            $.cookie(COOKIE_MAP_TYPE, Consts.sMAP_TYPE_OS, COOKIE_DO_NOT_EXPIRE);
        }
        $.cookie(COOKIE_DEFAULT_ZOOM, defaultZoom, COOKIE_DO_NOT_EXPIRE);
        DefMapCentreLat = $.cookie(COOKIE_DEFAULT_CENTRE_LAT);
    }

    var DefMapCentreLng = $.cookie(COOKIE_DEFAULT_CENTRE_LNG);
    var DefMapZoom = parseInt($.cookie(COOKIE_DEFAULT_ZOOM));

    iDefaultZoomLevel = parseInt(DefMapZoom);

    if ((DefMapCentreLat != 0) && (DefMapCentreLng != 0) && (DefMapZoom != null)) {
        SetMapCentreAndZoom(map, DefMapCentreLat, DefMapCentreLng, DefMapZoom, SetOptionsOnly);
    }
}


function SetLocalMapCentreAndZoom(EntityIndex) {
    // Retrieve the Local Map Latitude, Longitude and ZoomLevel for the given EntityIndex from the 
    // Reference data JSON object and pass to SetMapCentreAndZoom to position the map accordingly

    var thisEntity = sReferenceData[EntityIndex];

    console.log("Setting map to default centre & zoom to", EntityIndex);

    if (GOOGLE_ON == 1) {
        SetMapCentreAndZoom(map, thisEntity['__LMLL'][0], thisEntity['__LMLL'][1],
            thisEntity['__LMZL'], false);
    }
    else if (OS_ON == 1) {
        SetMapCentreAndZoom(map, thisEntity['LocalMap__NO'], thisEntity['LocalMap__EA'],
            thisEntity['__LMZL'], false);
    }
}

function EmptyStringIfNull(sString) {
    // Return the given string if not null, otherwise return the empty string
    if ((sString == null) || (sString == "undefined")) {
        return ("");
    }
    else {
        return (sString);
    }
}

function ZeroIfNull(sString) {
    // Return the given string if not null, otherwise return 0
    if (sString == null) {
        return (0);
    }
    else {
        return (sString);
    }
}

function ClassGetRSStateColourVal(EntityIndex, RSIndex) {
    // Return the "spate_level" class name based on the given Entity's Reading Station StateColourVal

    return (Consts.sCLASS_ROOT_SPATE_LEVEL + sDynamicData[EntityIndex].RSData[RSIndex]['__SCV']);
}

function ClassGetEntityStateColourVal(EntityIndex) {
    // Return the "spate_level" class name based on the given Entity's OverallStateColourVal

    return (Consts.sCLASS_ROOT_SPATE_LEVEL + sDynamicData[EntityIndex]['__OSCL']);
}

function GetMHRLDate(dateString) {
    //    var date = new Date("2010-09-21T15:48:12.754+02:00");
    var date = new Date(dateString);
    var Shortdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var myDate, myHours, myMinutes, mySeconds, myDayNo, myAMPM;

    myHours = date.getHours();
    myMinutes = date.getMinutes();
    mySeconds = date.getSeconds();
    myDayNo = date.getDate();
    if (myHours > 12) {
        myHours -= 12;
        myAMPM = "PM";
    }
    else {
        myAMPM = "AM";
    }

    //    if (myHours < 10) { myHours = "0" + myHours; }
    if (myMinutes < 10) { myMinutes = "0" + myMinutes; }
    if (mySeconds < 10) { mySeconds = "0" + mySeconds; }
    if (myDayNo < 10) { myDayNo = "0" + myDayNo; }

    // Don't bother with seconds
    myDate = Shortdays[date.getDay()] + " " + myDayNo + " " + months[date.getMonth()] + " " + date.getFullYear() + " ";
    myDate += myHours + ":" + myMinutes + " " + myAMPM;
    //    myDate += ":" + mySeconds;
    //    myDate += "0" + date.getTimezoneOffset() / 60 + ":00 " + date.getFullYear();
    return (myDate);
}

function GetFullMHRLDate(dateString) {
    String.Format("{0:ddd dd MMMM, HH:mm:ss}", dateString)
}

function LogTime() {
    var date = new Date()
    return date.getHours().toString() + ":" + date.getMinutes().toString() + ":" + date.getSeconds().toString() + "." + date.getMilliseconds().toString()
}

function TimedConsoleLog(msg) {
    console.log(LogTime() + ":" + msg);
}

// ******* Date/time formatting
//String.Format("{0:y yy yyy yyyy}", dt);  // "8 08 008 2008"   year
//String.Format("{0:M MM MMM MMMM}", dt);  // "3 03 Mar March"  month
//String.Format("{0:d dd ddd dddd}", dt);  // "9 09 Sun Sunday" day
//String.Format("{0:h hh H HH}",     dt);  // "4 04 16 16"      hour 12/24
//String.Format("{0:m mm}",          dt);  // "5 05"            minute
//String.Format("{0:s ss}",          dt);  // "7 07"            second
//String.Format("{0:f ff fff ffff}", dt);  // "1 12 123 1230"   sec.fraction
//String.Format("{0:F FF FFF FFFF}", dt);  // "1 12 123 123"    without zeroes
//String.Format("{0:t tt}",          dt);  // "P PM"            A.M. or P.M.
//String.Format("{0:z zz zzz}",      dt);  // "-6 -06 -06:00"   time zone

