var iCurrentStatsEntity;
var iDefaultStrokeWidth = 2;
var bLabellingAllRivers = false;
var bLabellingSelected = true;
var bFavouritesOn = false;
var bStartupComplete = false;
var myBarChart, currentChartFilename, currentGraphLink, currentUnits;
var graphOptions;
var searchTimer;
var FavouritesHTML;
var workerFave, workerSearch;

// *******************************************************************
// ***** River Entry Favourite/Unfavourite IMAGE Click Function ****** 
// *******************************************************************
//TODO:$$$ Replace with the use of badges / buttons
function MarkFavourite(thisElement) {
    var thisMnemonic = $(thisElement).next().next().text();
    console.log("*************** Extracted ", thisMnemonic);

    thisPtr = $(thisElement);
    thisPtr.addClass("UnFavouriteMe");
    thisPtr.removeClass("FavouriteMe");
    thisPtr.attr('src', 'images/UFM.png');

    // Add the Entity to the EntityFavourite Cookie value
    sEntityFave = CookieAddValue(COOKIE_ENTITY_FAVE, sEntityFave, thisMnemonic);
}

// ***************************************************************
// ***** Show Favourites Only / Show All link Click Function ***** 
// ***************************************************************

// Now handled a different way
//function ToggleFavourite(thisElement) {
//    console.log ("Favourites Only click", thisElement);

//    if (thisElement.hasClass("ShowAll")) {
//        console.log (TimedConsoleLog ("Doing Show All"));
//        // Currently showing Favourites only, now Show All
//        $(thisElement).removeClass("FavouritesOnly");
//        console.log(TimedConsoleLog("Doing the #RList div bulk slidedown"));
//        $('#RList > div').slideDown("slow");
//        //$('#RList div').slideDown("slow");
//        console.log(TimedConsoleLog("Tis done"));
//        $('#FavouritesOnly').text('Favourites');
//        $('#FavouritesOnly').removeClass('ShowAll');
//        $('#FavouritesOnly').addClass('FavesOnly');
//        bFavouritesOn = false;
//    }
//    else {
//        // Currently showing all, now Show Favourites only
//        console.log(TimedConsoleLog("Doing Faves Only"));
//        $(thisElement).addClass("FavouritesOnly");
//        $('#FavouritesOnly').text('Show All');
//        $('#FavouritesOnly').removeClass('FavesOnly');
//        $('#FavouritesOnly').addClass('ShowAll');
//        console.log(TimedConsoleLog("The parent is ", $('#RList a img.FavouriteMe').parents().eq(2)));
//        //                $('#RList a img.FavouriteMe').parents().eq(2).slideUp();
//        $('#RList a img.FavouriteMe').each(function() {
//            $(this).parents().eq(2).slideUp("slow");
//        });
//        $("#RiverSection").scrollTop(0);
//        bFavouritesOn = true;
//        //                $('#FavouritesOnly a').text('Show All Rivers\<img alt="" src="images/FavouriteMe.PNG" /\>');
//    }

//    TimedConsoleLog("Finished Favouriting");
////        $("#RiverSection").getNiceScroll().resize();
////    $("#divRiverList").getNiceScroll().resize();
//    return false;
//}


function DisplayPolyLines() {
    // Each of the arrays 'sEntityMarkerData', 'sRSMarkerData' and 'sPolyData' have the same number of
    // first dimension elements, each denoting content for a single entity. Hence, each array 
    // needs to carry data for entities in the same order.
    // Parse the 3 arrays containing details of each river's Entity marker, reading station markers and 
    // polylines. Draw each component on the map object and add Event Listeners to highlight the 
    // entity marker, reading stations, polylines and RiverList entry on the mouseover of any of these. 
    // At the same time, display the latest river level data in the Stats panel for the selected entity
    // from the Dynamic data structure
    // If mouseover of any reading station markers occurs when the associated river entity has already been 
    // selected, highlight the individual DIV for that RS's displayed statistic
    // If a RS DIV receives a mouseover, highlight the RS marker on the map. ^^ Don't know if this is done yet.

    //+__+

    var pos, size, offset, infoWindowAnchor, icon, content, popUpSize;

    var thisReferenceEntry, thisDynamicEntry, thisInfoContent;
    var thisRefRSData, thisDynRSData;
    var thisEntityImagePlain, thisEntityImageFocus, thisRSImagePlain, thisRSImageFocus;
    var thisPolyListArray;
    var imgObject = {};
    var polyHighlight = {
        strokeColor: "#00FFFF"
    };

    console.log("DisplayPolyLines");
    // Iterate through the Reference and Dynamic arrays
    // Create the Entity Markers
    for (var i = 0, ilimit = sDynamicData.length; i < ilimit; i++) {

        thisReferenceEntry = sReferenceData[i];
        thisDynamicEntry = sDynamicData[i];

        // Create the Entity LatLng object
        if (MAPS_ON == 1) {
            //+_+
            if (GOOGLE_ON == 1) {
                //+_+
                arrEntityLatLng[i] = new google.maps.LatLng(thisReferenceEntry['__ELL'][0],
                    thisReferenceEntry['__ELL'][1]);

                // Determine the Entity images to be used (with and without Focus)
                thisEntityImagePlain = GetEntityLevelTrendImageName(thisReferenceEntry['__Mne'], false, (GOOGLE_ON == 1));
                thisEntityImageFocus = GetEntityLevelTrendImageName(thisReferenceEntry['__Mne'], true, (GOOGLE_ON == 1));

                // Create an object with Plain and Focus properties for the entity to be used later with mouseover events
                // Need to do the same for Reading Stations
                arrEntityImages[i] = new Object();
                arrEntityImages[i]['ImgPlain'] = thisEntityImagePlain;
                arrEntityImages[i]['ImgFocus'] = thisEntityImageFocus;
            }
            else if (OS_ON == 1) {
                //+_+
            }
        }

        // *********************************************
        // ************* ENTITY MARKERS ****************
        // *********************************************

        // and now the Entity marker itself
        if (SHOW_MAP_ENT == 1) {
            if (MAPS_ON == 1) {
                //+_+
                //console.log("Maps ON");
                if (GOOGLE_ON == 1) {

                    arrEntityMarker[i] = new google.maps.Marker({
                        position: arrEntityLatLng[i],
//                        icon: thisEntityImagePlain,
                        visible: false, 
                        label: thisReferenceEntry['__Mne'],
                        title: 'River ' + thisReferenceEntry['Name']
                    });

//                    arrEntityMarker[i] = new google.maps.Marker({
//                        position: arrEntityLatLng[i],
//                        icon: thisEntityImagePlain,
//                        visible: false,
//                        title: 'River ' + thisReferenceEntry['Name']
//                    });
                    //console.log ('Entity Marker being mapped', i);
                    // Don't place the Entity marker on the map or create its listener just yet
                    //+_+$^$
                    arrEntityMarker[i].setMap(map);
                }
                else if (OS_ON == 1) {
                    //console.log("Creating OS Marker " + thisReferenceEntry['Name']);
                    thisInfoContent = '<a href="#">River ' + thisReferenceEntry['Name'] + '</a>';

                    //+__+ get rid of Pos, no longer needed

                    // ^$^ Needs initially to be invisible

                    arrEntityMarker[i] = new OpenLayers.Feature.Vector(new OpenLayers.Geometry.Point(parseInt(thisReferenceEntry['Ent__EA']),
                        parseInt(thisReferenceEntry['Ent__NO'])),
                        { "lbl": thisReferenceEntry['Name'], "EntityID": i });


                    EntityLayer.addFeatures(arrEntityMarker[i]);
                }


            }
            sRiverTag = "#" + Consts.sENTITY_TAG + i; //^^ Delete this line

            // Add mouseover and mouseout listener events for the Entity Marker
            if (MAPS_ON == 1) {
                if (GOOGLE_ON == 1) {
                    google.maps.event.addListener(arrEntityMarker[i], 'mouseover', (function (i) {
                        return function () {
                            EntityMouseover(i);
                            // Display the default graph type 1
                            if (SHOW_STATS == 1) {
                                ShowDefaultGraph();
                            }
                            //                MarkEntityElements (i, true, Consts.sPOLY_COLOUR_WITH_FOCUS, Consts.sIMG_ROOT + sEntityMarkerData[i][2])
                        }
                    })(i));
                }
                else if (OS_ON == 1) {
                    //+_+

                    // +_+ ADD THIS BACK IN IF THE FEATURE LEVEL EVENT HANDLING DOESN'T WORK
                }
            }
        }

        // ******************************************************
        // ************* READING STATION MARKERS ****************
        // ******************************************************

        //console.log("RS Marker Prep");
        if (SHOW_MAP_RS == 1) {

            // Create the Reading Station Markers
            // First, create arrays for the current Entity's set of Reading Stations for each one's 
            // LatLng, Marker and InFocus/Plain images
            arrRSLatLng[i] = new Array();
            arrRSMarker[i] = new Array();
            arrEntityRSImages[i] = new Array();

            for (var j = 0, jlimit = thisReferenceEntry['RSData'].length; j < jlimit; j++) {

                // Setup object to point to current Reference and Dynamic JSON Elements
                thisRefRSData = sReferenceData[i]['RSData'][j];
                thisDynRSData = sDynamicData[i]['RSData'][j];
                //                thisRefRSData = thisReferenceEntry[j];
                //                thisDynRSData = thisReferenceEntry[j];
                // Determine the RS images to be used (with and without Focus)
                //console.log ("Getting Trend Image ", j);
                thisRSImagePlain = GetRSLevelTrendImageName(j, false);
                thisRSImageFocus = GetRSLevelTrendImageName(j, true);

                // Create an object with Plain and Focus properties for the RS Marker to be used later with 
                // mouseover events
                arrEntityRSImages[i][j] = new Object();
                arrEntityRSImages[i][j]['ImgPlain'] = thisRSImagePlain;
                arrEntityRSImages[i][j]['ImgFocus'] = thisRSImageFocus;
                //console.log ("Mapping RS Marker Image Plain " + arrEntityRSImages[i][j]['ImgPlain']);
                //console.log ("Mapping RS Marker Image Focus " + arrEntityRSImages[i][j]['ImgFocus']);

                // Create the RS Markers for the current entity
                //console.log ("Starting loop [", j, "] through the RS Marker Loop for Entity ", i);
                if (MAPS_ON == 1) {
                    //+_+
                    if (GOOGLE_ON == 1) {
                        arrRSLatLng[i][j] = new google.maps.LatLng(thisRefRSData['__LL'][0], thisRefRSData['__LL'][1]);
                        arrRSMarker[i][j] = new google.maps.Marker({
                            position: arrRSLatLng[i][j],
                            icon: thisRSImagePlain,
                            visible: false,
                            title: thisReferenceEntry['Name'] + ", " + thisRefRSData['Name'] + " ( " + thisRefRSData['__Mne'] + ")"
                            //+_+
                        });
                        //console.log ('!!!!!!!!!!!!!!!!! RS Marker being mapped ', i, j, arrRSMarker[i][j]);

                        // Don't place the RS Marker on the map or create its listener at this point ^^
                        // +_+
                        arrRSMarker[i][j].setMap(map);
                        // Add mouseover and mouseout listener events for the RS Entity Marker
                        google.maps.event.addListener(arrRSMarker[i][j], 'mouseover', (function (i, j) {
                            return function () {
                                RSFocus(i, j, true)
                            }
                        })(i, j));

                        google.maps.event.addListener(arrRSMarker[i][j], 'mouseout', (function (i, j) {
                            return function () {
                                RSFocus(i, j, false)
                            }
                        })(i, j));
                        //+_+

                        // Also add a mouse click event that will display all elements of the related Entity
                        // when an RS Marker is clicked
                        //+_+
                        google.maps.event.addListener(arrRSMarker[i][j], 'click', (function (i, j) {
                            return function () {
                                RSMarkerClick(i, j)
                                // Display the default graph type 1
                                if (SHOW_STATS == 1) {
                                    ShowDefaultGraph();
                                }
                            }
                        })(i, j));
                    }
                    else if (OS_ON == 1) {
                        //console.log ("Mapping OS RS Markers!!!!!!!!!!!");
                        // +_+

                        thisInfoContent = '"<a href="#"> River ' + thisReferenceEntry['Name'] + ', ' +
                            thisRefRSData['Name'] + ' ( ' + thisRefRSData['__Mne'] + ')' + '</a>';

                        arrRSLatLng[i][j] = new OpenLayers.LonLat(parseInt(thisRefRSData['__EA']),
                            parseInt(thisRefRSData['__NO']));

                        size = new OpenLayers.Size(33, 45);
                        offset = new OpenLayers.Pixel(-16, -36);
                        infoWindowAnchor = new OpenLayers.Pixel(16, 16);

                        icon = new OpenLayers.Icon(thisRSImagePlain, size, offset);

                        arrRSMarker[i][j] = new OpenLayers.Marker(arrRSLatLng[i][j], icon);

                        //console.log("display off");
                        arrRSMarker[i][j].display(false);
                        RSLayer.addMarker(arrRSMarker[i][j]);
                        //+_+

                        // Don't place the RS Marker on the map or create its listener at this point ^^
                        // Add mouseover and mouseout listener events for the RS Entity Marker

                        // $^$ All events here to be removed. Now not sure why. Though we can get away without these events

                        arrRSMarker[i][j].events.register('mouseover', arrRSMarker[i][j], (function (i) {
                            return function () {
                                RSFocus(i, j, true)
                            }
                        })(i, j));

                        arrRSMarker[i][j].events.register('mouseout', arrRSMarker[i][j], (function (i, j) {
                            return function () {
                                RSFocus(i, j, false)
                            }
                        })(i, j));
                        //+_+

                        // Also add a mouse click event that will display all elements of the related Entity
                        // when an RS Marker is clicked
                        //+_+
                        arrRSMarker[i][j].events.register('click', arrRSMarker[i][j], (function (i, j) {
                            return function () {
                                RSMarkerClick(i, j)
                                // Display the default graph type 1
                                if (SHOW_STATS == 1) {
                                    ShowDefaultGraph();
                                }
                            }
                        })(i, j));

                        // $^$ All events here to be removed

                    }
                }


            }   // loop end

        }


        // ******************************************************
        // ********************* POLYLINES **********************
        // ******************************************************

        if (SHOW_MAP_ENT == 1) {
            // Create the Entity Polylines and event listeners
            //console.log('!!!!!!Create Polys');

            // Create a new array for the set of Polylines (and PolyFeatures for OS Maps) for the current Entity
            arrPolyLines[i] = new Array();
            arrPolyFeature[i] = new Array();
            // And an array to receive the PolyLine points for the current segment of the polyline
            arrPolyLatLngPoints[i] = new Array();

            // The first loop (k) traverses over each segment of this Entity's (i) Polyline
            for (var k = 0, klimit = thisReferenceEntry['__PL'].length; k < klimit; k++) {
                // The second loop (l) traverses over the inidivual LatLng Coordinates of the current (k) segment
                // Create an array ready receive the set of 1 or more LatLng points for the current segment
                arrPolyLatLngPoints[i][k] = new Array();
                thisPolyListArray = thisReferenceEntry['__PL'][k];
                //console.log ("Mapping points for " + k);$^$
                for (var l = 0, llimit = thisPolyListArray.length; l < llimit; l++) {
                    // Create the Polyline for the current entity, current segment
                    //console.log("In loop " + thisPolyListArray.length);
                    if (MAPS_ON == 1) {
                        //+_+
                        // *** NOTE : The Reference Data JSON Array should be populated with only 2 elements for each point
                        // ***        accordingly with LatLng or Easting/Northing dependent on whether GoogleMaps or OSMaps
                        // ***        will be used in order to massively reduce the size of the JSON file
                        if (GOOGLE_ON == 1) {
                            arrPolyLatLngPoints[i][k].push(new google.maps.LatLng(thisPolyListArray[l][0], thisPolyListArray[l][1]));
                        }
                        else if (OS_ON == 1) {
                            //+_+
                            // Note the use of array subelements [2] & [3] assumes that only the coordinates
                            // relevant for this graphing suite have been created
                            arrPolyLatLngPoints[i][k].push(new OpenLayers.Geometry.Point(thisPolyListArray[l][0], thisPolyListArray[l][1]));
                            //    arrPolyLatLngPoints[i][k].push(new OpenLayers.Geometry.Point(thisPolyListArray[l][2], thisPolyListArray[l][3]));
                        }
                    }
                }

                //                k = arrPolyLatLngPoints.length - 1; //^^ Don't understand what this is here for

                // Check that we're mapping in general and that mapping is On for this entity
                if ((MAPS_ON == 1) && (thisReferenceEntry['__MO'] == 1)) {
                    //+_+
                    if (GOOGLE_ON == 1) {
                        arrPolyLines[i][k] = new google.maps.Polyline({
                            path: arrPolyLatLngPoints[i][k], strokeColor: ConvertColourNum(thisDynamicEntry['PolyColour'][k]), strokeOpacity: 1.0,
                            clickable: true, strokeWeight: 2
                        });
                        // Add the Polyline to the map
                        arrPolyLines[i][k].setMap(map);
                        // Add mouseover and mouseout listener events for the Polyline
                        google.maps.event.addListener(arrPolyLines[i][k], 'mouseover', (function (i) {
                            return function () {
                                //                MarkEntityElements (i, true, Consts.sPOLY_COLOUR_WITH_FOCUS, Consts.sIMG_ROOT + sEntityMarkerData[i][2])
                                EntityMouseover(i);
                                // Display the default graph type 1
                                if (SHOW_STATS == 1) {
                                    ShowDefaultGraph();
                                }
                                iCurrentStatsEntity = i;
                                $.cookie(COOKIE_ENTITY_LAST_SELECT, iCurrentStatsEntity.toString(), COOKIE_DO_NOT_EXPIRE);
                                ShowAllRSTextAlertSamples();
                            }
                        })(i));
                    }
                    else if (OS_ON == 1) {
                        //console.log ("Creating line string and feature for " + k);
                        arrPolyLines[i][k] = new OpenLayers.Geometry.LineString(arrPolyLatLngPoints[i][k]);
                        PolySegNo = i.toString() + "_" + k.toString();
                        arrPolyFeature[i][k] = new OpenLayers.Feature.Vector(arrPolyLines[i][k],
                            { "lbl": thisReferenceEntry['Name'], "EntityID": i, "PolySeg": PolySegNo },
                            {
                                strokeColor: ConvertColourNum(thisDynamicEntry['PolyColour'][k]), strokeOpacity: 1
                                //                            strokeWidth: 5

                            });
                        arrPolyLines[i][k].attributes = { "EntityID": i };
                        // Add the Polyline to the map

                        VectorLayer.addFeatures(arrPolyFeature[i][k]);

                        // $^$^$ Register a listener event here

                        //                        console.log("Original call");


                    }
                }

            }
        }
    }
    console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ Polys DONE');
    if (OS_ON == 1) {
        //console.log("!!!!!!!!!!!!!!!!!!!!!!!!!Adding ayer to map");

    }
};

// $$

//TODO: Do we need these example any more?

function ShowAllRSTextAlertSamples() {
    var thisSample;
    var iRSCount = sReferenceData[iCurrentStatsEntity]['RSData'].length;

    if (SHOW_TEXT_SAMPLES == 1) {

        TimedConsoleLog("ShowAllRSTextAlertSamples");
        // River Latest Levels
        $('.popupEntity').html(sReferenceData[iCurrentStatsEntity]['Name']);
        //TODO: ^^^ HomeEntity now redundant?
        $('.popupHomeEntity').html(sReferenceData[iCurrentStatsEntity]['Name']);
        $('.riverLastUpdate').html(GetMHRLDate(sDynamicData[iCurrentStatsEntity]['__CSSDT']));

        $('.popupEntityMnemonic').html(sReferenceData[iCurrentStatsEntity]['__Mne']);
        if (sReferenceData[iCurrentStatsEntity]['Loc'] != undefined)
            $('.popupEntityLoc').html(sReferenceData[iCurrentStatsEntity]['Loc'])
        else
            $('.popupEntityLoc').html("");
        // Location Text Alerts
        $('#popupRSSamples').empty();
        for (var RSIndex = 0, ilimit = iRSCount; RSIndex < ilimit; RSIndex++) {

            thisSample = '<li class="liOuter"> <span id="pRS' + RSIndex + '" class="popupRS"> </span>';
            thisSample += '<br/>';
            thisSample += '<li class="textOut"><span class="textLook">Text</span> <span class="textFmt"> LEVEL ';
            thisSample += '<span id="pEM' + RSIndex + '" class="popupEntityMnemonic"> </span>&nbsp;<span id="pRSM' + RSIndex + '" class="popupRSMnemonic"> </span>&nbsp;<span class="popupLevel2"> </span>&nbsp;</span><br> to 07860 077 377 </li></li>';
            thisSample += '<hr>';

            $('#popupRSSamples').append(thisSample);

            $('#pRS' + RSIndex).html(sReferenceData[iCurrentStatsEntity]['RSData'][RSIndex]['Name']);
            $('#pEM' + RSIndex).html(sReferenceData[iCurrentStatsEntity]['__Mne']);
            $('#pRSM' + RSIndex).html(sReferenceData[iCurrentStatsEntity]['RSData'][RSIndex]['__Mne']);

        }

        $('.popupLevel2').html((0.75 + (2 * Math.random())).toFixed(2));
        TimedConsoleLog("ShowAllRSTextAlertSamples End - resize");

        // Resize the scrollbar
        try {
            //            $("#MobileAlertExamples").getNiceScroll().resize();
        }
        catch (err5) { }

        TimedConsoleLog("ShowAllRSTextAlertSamples End - resize end");
    }
}

// Display random Text Alert help for the given RSIndex of the currently select entity
function ShowRSSample(RSIndex) {
    var EntandMnemonic, theLoc;


    if (SHOW_TEXT_SAMPLES == 1) {
        $('.popupEntity').html(sReferenceData[iCurrentStatsEntity]['Name']);
        $('.popupRS').html(sReferenceData[iCurrentStatsEntity]['RSData'][RSIndex]['Name']);
        $('.popupEntityMnemonic').html(sReferenceData[iCurrentStatsEntity]['__Mne']);
        $('.popupRSMnemonic').html(sReferenceData[iCurrentStatsEntity]['RSData'][RSIndex]['__Mne']);
        $('.popupLevel1').html((0.5 + Math.random()).toFixed(2));
        $('.popupLevel2').html((0.75 + (2 * Math.random())).toFixed(2));
        $('.popupLevel3').html((0 + Math.random()).toFixed(2));


        if (sReferenceData[iCurrentStatsEntity]['Loc'] != undefined)
            theLoc = sReferenceData[iCurrentStatsEntity]['Loc']
        else
            theLoc = "";

        EntandMnemonic = '<span class="popupEntityLoc textHigh">' +
            theLoc + '</span>' + ' ' + sReferenceData[iCurrentStatsEntity]['Name'] + " at " +
            sReferenceData[iCurrentStatsEntity]['RSData'][RSIndex]['Name'];
        $('#stats_TextEntMne').html(EntandMnemonic);
    }
}

// Display random Text Alert help for the given RSIndex of the currently select entity
function TextAlertFocus(RSIndex) {
    var EntandMnemonic, theLoc;

    if (SHOW_TEXT_SAMPLES == 1) {

        $('.popupEntity').html(sReferenceData[iCurrentStatsEntity]['Name']);
        $('.popupRS').html(sReferenceData[iCurrentStatsEntity]['RSData'][RSIndex]['Name']);
        $('.popupEntityMnemonic').html(sReferenceData[iCurrentStatsEntity]['__Mne']);
        $('.popupRSMnemonic').html(sReferenceData[iCurrentStatsEntity]['RSData'][RSIndex]['__Mne']);
        $('.popupLevel1').html((0.5 + Math.random()).toFixed(2));
        $('.popupLevel2').html((0.75 + (2 * Math.random())).toFixed(2));
        $('.popupLevel3').html((0 + Math.random()).toFixed(2));


        if (sReferenceData[iCurrentStatsEntity]['Loc'] != undefined)
            theLoc = " (" + sReferenceData[iCurrentStatsEntity]['Loc'] + ")";
        else
            theLoc = "";

        $('.popupEntityPl').html(sReferenceData[iCurrentStatsEntity]['Name'] + theLoc);

        EntandMnemonic = '<span class="popupEntityLoc textHigh">' +
            theLoc + '</span>' + ' ' + sReferenceData[iCurrentStatsEntity]["Name"] + ' at ' +
            sReferenceData[iCurrentStatsEntity]["RSData"][RSIndex]["Name"];
        $('#stats_TextEntMne').html(EntandMnemonic);
    }
}

// Get a random RS index from the currently select entity
function GetRandomRSIndex() {
    var useIndex, useLimit;

    //alert(sReferenceData[iCurrentStatsEntity]['RSData'].length);

    useLimit = sReferenceData[iCurrentStatsEntity]['RSData'].length;

    useIndex = Math.floor(Math.random() * useLimit);
    if (useIndex > useLimit) {
        useIndex = useLimit;
    }
    TextAlertFocus(useIndex);
}

function RSFocus(EntityIndex, RSIndex, HighlightOn) {
    // A Mouseover or Mouseout event has occurred on an RS Marker or an RS's stats table row.
    // Highlight the row containing the associated Reading Stations's stats table row
    // and display the Focus version of the RS Marker after removing highlighting
    // for any previously focussed RS stats table tow.

    var sTagName;

    console.log('RS Focus called Entity/RS/Highlight/iPrevRSNum', EntityIndex, RSIndex, HighlightOn, iPrevRSNum);

    // If the Entity this Reading Station refers to is no currently the Entity being
    // displayed in the Stats table, do not respond to the event
    if (EntityIndex == iCurrentStatsEntity) {
        if ((iPrevRSNum == RSIndex) && (iTimerRSHighlight != Consts.NotDefined))
        // If the same Entity as was previously highlighted is being highlighted again
        // and the timer request to return it to normal video is still running, cancel and restart it
        {
            clearTimeout(iTimerRSHighlight);
            iTimerRSHighlight = setTimeout(function () { HighlightRSMarker(EntityIndex, RSIndex, false); }, Consts.sENTITY_HIGHLIGHT_TIME_MS);
            return;
        };

        // If the same RS is still in focus, do nothing
        if (RSIndex != iPrevRSNum) {
            if (iPrevRSNum != Consts.NotDefined) {
                if (iTimerRSHighlight != Consts.NotDefined) {
                    ForceRSResetTimeout(iTimerRSHighlight, EntityIndex, iPrevRSNum)
                }

                // Remove the "highlight" class from both the top and bottom row of the previous entry
                sTagName = "#stats_row_top__" + iPrevRSNum;
                $(sTagName).removeClass('highlight');
                sTagName = "#stats_row_bot__" + iPrevRSNum;
                $(sTagName).removeClass('highlight');
                // Set the RS Marker to its plain image
                //                HighlightRSMarker (EntityIndex, iPrevRSNum, false);
                //console.log ("class content bot old now ", sTagName, " : ",  $(sTagName).html());
            }


            // Add the "highlight" class to both the top and bottom row of the previous entry
            sTagName = "#stats_row_top__" + RSIndex;
            $(sTagName).addClass('highlight');
            sTagName = "#stats_row_bot__" + RSIndex;
            $(sTagName).addClass('highlight');
            // Now set the current RS Marker to its Focus Image and set the reset timer again
            HighlightRSMarker(EntityIndex, RSIndex, true);
            //console.log ("class content BOT NEW now ", sTagName, " : ",  $(sTagName).html());
            iTimerRSHighlight = setTimeout(function () { HighlightRSMarker(EntityIndex, RSIndex, false); }, Consts.sENTITY_HIGHLIGHT_TIME_MS);

            iPrevRSNum = RSIndex;
        }
        TextAlertFocus(RSIndex);
    }
};

function RSMarkerClick(EntityIndex, RSIndex) {
    // An RS Marker has been clicked. Highlight the associated Entity elements by calling EntityMouseover
    // Highlight the row containing the associated Reading Stations's stats table row
    // and display the Focus version of the RS Marker after removing highlighting
    // for any previously focussed RS stats table tow.

    console.log('RSMarkerClick called Entity/RSIndex', EntityIndex, RSIndex);

    EntityMouseover(EntityIndex);
};

function RSStateDescription(sRSState) {
    // Return the description as in EntityList.xslt which matches the given state image - e.g. "state6F.png"

    if (sRSState == "state1S.png") { return "Dead low, steady" }
    else if (sRSState == "state1R.png") { return "Dead low, rising" }
    else if (sRSState == "state1F.png") { return "Dead low, falling" }
    else if (sRSState == "state2S.png") { return "Low, steady" }
    else if (sRSState == "state2R.png") { return "Low, rising" }
    else if (sRSState == "state2F.png") { return "Low, falling" }
    else if (sRSState == "state3S.png") { return "Around normal, steady" }
    else if (sRSState == "state3R.png") { return "Around normal, rising" }
    else if (sRSState == "state3F.png") { return "Around normal, falling" }
    else if (sRSState == "state4S.png") { return "Above normal, steady" }
    else if (sRSState == "state4R.png") { return "Above normal, rising" }
    else if (sRSState == "state4F.png") { return "Above normal, falling" }
    else if (sRSState == "state5S.png") { return "Well above normal, steady" }
    else if (sRSState == "state5R.png") { return "Well above normal, rising" }
    else if (sRSState == "state5F.png") { return "Well above normal, falling" }
    else if (sRSState == "state6S.png") { return "Spate, steady" }
    else if (sRSState == "state6R.png") { return "Spate, rising" }
    else if (sRSState == "state6F.png") { return "Spate, falling" }
    else if (sRSState == "state7S.png") { return "Heavy spate, steady" }
    else if (sRSState == "state7R.png") { return "Heavy spate, rising" }
    else if (sRSState == "state7F.png") { return "Heavy spate, falling" }
    else if (sRSState == "state0.png") { return "Unknown" }
};


function ShowEntityStats(EntityNum, bForceRefresh)
// Extract the Entity and Level details from a combination of the Static and Dynamic JSON structures
// and generate the appropriate HTML within ths "stats_Body" container to display the results
// Set the global variable iCurrentStatsEntity to denote the Entity number for which stats on Reading
// Stations are currently on display in the Stats Area
// The bForceRefresh parameter is used to force an update when the "SwitchUnits" button has been pressed
{

    var thisColourVal, arrLevelDetails, iIndex, fmtDate, sRSState;

    TimedConsoleLog("ShowEntityStats start " + EntityNum + " / " + iCurrentStatsEntity);
    // No need to redisplay if this river is already on display
    if ((iCurrentStatsEntity != EntityNum) || (bForceRefresh == true) || (bStartupComplete == false)) {
        // Empty the body of the table and recreate from the RS Static and Dynamic data for the given element
        TimedConsoleLog("HTML update");
        $('#stats_Body').empty();
        $('#stats_DateTime').html(GetMHRLDate(sDynamicData[EntityNum]['__CSSDT']));
        $('#stats_DateTimeAgo').attr("title", sDynamicData[EntityNum]['__CSSDT']);
        $('#stats_DateTimeAgo').data("timeago", null).timeago();

        jQuery("abbr.timeago").timeago();

        //TODO: $$$ REmove NUDT element from json
        //$('#stats_NextUpdate').html(GetMHRLDate(sDynamicData[EntityNum]['__NUDT']));
        $('#stats_Entity').html(sReferenceData[EntityNum]['Name']);
        $('#stats_Mnemonic').html('(' + sReferenceData[EntityNum]['__Mne'] + ')');
        console.log("******* Stats Mnemonic is " + EntityNum + sReferenceData[EntityNum]['__Mne']);
        iIndex = 0;

        TimedConsoleLog("ref data parse");
        $.each(sReferenceData[EntityNum]['RSData'], function (entryIndex, entry) {
            // Retrieve the settings and state in the required units from the Dyanmic data JSON
            // and add as 2 rows to the Stats Display

            // @@@ Remove __DL and __UC from reference data and this call
            //arrLevelDetails = GetLevelDetails(entry['__DL'], sReferenceData[EntityNum]['__UC'],
            arrLevelDetails = GetLevelDetails(
                sDynamicData[EntityNum]['RSData'][entryIndex]['Level'],
                sDynamicData[EntityNum]['RSData'][entryIndex]['__LI'],
                sDynamicData[EntityNum]['RSData'][entryIndex]['__LPC'],
                sDynamicData[EntityNum]['RSData'][entryIndex]['__RFS'],
                sDynamicData[EntityNum]['RSData'][entryIndex]['__SCV'],
                sUnits);

            thisColourVal = sDynamicData[EntityNum]['RSData'][entryIndex]['__SCV'];

            //                console.log (arrLevelDetails.pop());
            //                console.log (arrLevelDetails.pop());
            //                console.log (arrLevelDetails.pop());
            //                console.log (arrLevelDetails.pop());
            //                console.log (arrLevelDetails.pop());
            //            // Relative Level, Absolute Level, LevelImg, TrendImg, LevelDesc, TrendDesc

            // Insert the ** FIRST LINE **, include the class to denote Spate Level
            var html_str;
            html_str = '<tr id="stats_row_top__' + entryIndex + '" class= "d-flex stats_row_top ' + Consts.sCLASS_ROOT_SPATE_LEVEL +
                thisColourVal + '">';

            html_str += '<td scope="col" rowspan=3 class="col-1 RSIcon"> <img src="images/Markers/RSMkr' + (iIndex + 1) + 'PLAIN.png"/></td>';

            // Pop the Spate Level / Rise Fall state image name
            sRSState = arrLevelDetails.pop();

            // Reading Station state image e.g. state2R.png
            html_str += '<td scope="col" class="col-10 col_Guage" alt="' + entry['Name'] + '">' + entry['Name'] + ' (' + entry['__Mne'] + ')</td>';
            html_str += '<td scope="col" rowspan=3 class="col-1 RSState"> <img src="images/' + sRSState + '" title = "' + RSStateDescription(sRSState) + '" alt = "' + RSStateDescription(sRSState) + '"/></td>';
            //                html_str += '<td scope="col" rowspan=3 class="RSIcon"> <a href="#" class="putrig" id="putrig__' + entryIndex + '"><img src="css/images/TextMe.png" alt="Text Me"/></a></td>';
            html_str += '</tr>';


            //                html_str += '<td scope="col" class="col_Level" alt=' + "Level Reading" + '>' + arrLevelDetails.pop() + '</td>';

            // ^^ Ditch the Absolute level which isn't currently used
            // console.log ("-> ", arrLevelDetails.pop());

            // Above/Below/Normal image
            //                html_str += '<td scope="col" class="col_UpDown"> <img src="images/' + arrLevelDetails.pop() + '"/></td>';
            // Rising/Falling/Steady image
            //                html_str += '<td scope="col" class="col_UpDown"> <img src="images/' + arrLevelDetails.pop() + '"/></td>';

            // Add the new body html 
            //console.log ("Add [" + html_str);
            $('#stats_Body').append(html_str);

            // And now the ** SECOND LINE **, popping the level and trend
            html_str = '<tr id="stats_row_bot__' + entryIndex + '" class= "d-flex stats_row_bot">';
            html_str += '<td class="col-1"></td>';
            html_str += '<td scope="col" class="RSLevel" >' + arrLevelDetails.pop() + arrLevelDetails.pop() + '</td>';
            html_str += '</tr>';
            //            html_str += '</div>';
            // Add the new body html 
            //console.log ("Add [" + html_str);
            $('#stats_Body').append(html_str);

            // Add the bar indicator as a third line

            html_str = '<tr class= "d-flex stats_row_bar">';
            html_str += '<td class="col-1"></td>';
            html_str += '<td scope="col" class="ARLevel" >' + '<div class="ARBarLengthInner" style="width:' +
                arrLevelDetails.pop() + '%;" /></div></td>';
            html_str += '</tr>';
            $('#stats_Body').append(html_str);

            // Get ready for the next Reading Station
            iIndex += 1;

        });

        // Note the new Entity for which stats are now on display
        iCurrentStatsEntity = EntityNum;
        var rsCount = sReferenceData[EntityNum].RSData.length;
        if (rsCount <= 6) {
            $('#specific_stats').height('auto');
        } else {
            $('#specific_stats').height(6 * 77);

            //            $('#specific_stats').height($('statsDiv').height);
        }
        console.log("height is " + sReferenceData[EntityNum].RSData.length);
        TimedConsoleLog("Random RS Index");
        GetRandomRSIndex();

        try {
            //TODO:$$$ May need to be reinstated
            //            $("#specific_stats").getNiceScroll().resize();
        }
        catch (err6) { }

        TimedConsoleLog("ShowEntityStats end");

    }
};

function ToggleUnits(SetToUnits) {
    // Alternate between the various Units settings (Imperial and Metric at the time of writing)
    // calling the routine to redraw the appropriate map also

    if (SetToUnits != undefined) {
        sUnits = SetToUnits;
    };

    if ((sUnits == Consts.sUNIT_CODE_METRIC) || (SetToUnits == Consts.sUNIT_CODE_IMPERIAL)) {
        // Was Metric, go to Imperial
        sUnits = Consts.sUNIT_CODE_IMPERIAL;
        $('#unitsButton a').text(Consts.sUNIT_CODE_SWITCH_TO_METRIC);
    }
    else {
        // Was Imperial, go to Metric
        sUnits = Consts.sUNIT_CODE_METRIC;
        $('#unitsButton a').text(Consts.sUNIT_CODE_SWITCH_TO_IMPERIAL);
    }
    // Update the cookie
    $.cookie(COOKIE_UNITS, sUnits.toString(), COOKIE_DO_NOT_EXPIRE);
    // and update the graph
    //console.log ("show graph from toggle units");
    ShowGraph(iCurrentGraph);
};

function SetUnits(doSetOnly) {
    console.log("SetUnits Called");
    // Determine whether default units have been set. If not, set them up with a default
    if (SHOW_STATS == 1) {
        if (sUnits == undefined) {
            sUnits = EmptyStringIfNull($.cookie(COOKIE_UNITS));

            if (!doSetOnly) {
                if (sUnits == "") {
                    ToggleUnits(Consts.sUNIT_CODE_METRIC);
                } else {
                    ToggleUnits(sUnits);
                };
            } else {
                if (sUnits == Consts.sUNIT_CODE_METRIC) {
                    $('#unitsButton a').text(Consts.sUNIT_CODE_SWITCH_TO_IMPERIAL);
                }
                else {
                    $('#unitsButton a').text(Consts.sUNIT_CODE_SWITCH_TO_METRIC);
                }
            }
        }
    }

};

// If the Units have changed the Y Axis 
function GetGraphImperialUnits(svrData) {
    // TODO: Create the modified Imperial dataset if it does not exist

    var theMetricMin = svrData.ticks.min;
    var theMetricMax = svrData.ticks.max;
    //var theMetricStepsize = svr.dataset.ticks.stepSize;

    //    var theMin = (Math.floor(theMetricMin * Consts.MetricImpFactor)) - 1;
    //    var theMax = (Math.floor(theMetricMax * Consts.MetricImpFactor)) + 1;

    var theMin = (Math.floor((theMetricMin * Consts.MetricImpFactor) / 2) * 2);
    var theMax = (Math.floor((theMetricMax * Consts.MetricImpFactor) / 2) * 2);

    var theStepSize = (theMax - theMin > 10) ? 2 : 1;
    svrData.ticks.min = theMin;
    svrData.ticks.max = theMax;
    svrData.stepSize = theStepSize;

    //{
    //    "ticks": {
    //        "max": 6, "min": -1, "stepSize":


    for (var i = 0; i < svrData.datasets.length; i++) {
        for (var j = 0; j < svrData.datasets[i].data.length; j++) {
            svrData.datasets[i].data[j] *= Consts.MetricImpFactor;
        }
    }
    //for (var eachSet of svrData.datasets) {
    //    for (var level of eachSet) {

    //    }
    //}

}

function SetGraphLegendClickHandler() {

    var cjsLegendClickHandler = function (e, legendItem) {
        var index = legendItem.datasetIndex;
        var cht = this.chart;
        var meta = cht.getDatasetMeta(index);

        // See controller.isDatasetVisible comment
        meta.hidden = meta.hidden === null ? !cht.data.datasets[index].hidden : null;

        console.log("Toggling " + sReferenceData[iCurrentStatsEntity]['Name']);

        // We hid a dataset ... rerender the chart
    }

}


//*******************************************


function SetGraphDefaults() {

    // Global Options:
    Chart.defaults.global.defaultFontColor = 'black';
    Chart.defaults.global.defaultFontSize = 16;
    //Chart.defaults.global.datasets.line.showLine = false;
    Chart.defaults.global.elements.line.borderWidth = 2;
    Chart.defaults.global.elements.line.fill = false;
    Chart.defaults.global.elements.line.tension = 0.1;
    Chart.defaults.global.elements.point.pointRadius = 10;
    Chart.defaults.global.elements.point.radius = 0;
    Chart.defaults.global.elements.point.pointHitRadius = 60;
    Chart.defaults.global.elements.point.pointBorderColor = "black";
    Chart.defaults.global.elements.point.pointBackgroundColor = "white";
    Chart.defaults.global.elements.point.pointBorderWidth = 1;
    Chart.defaults.global.elements.point.pointHoverRadius = 60;
    Chart.defaults.global.elements.point.pointHoverBackgroundColor = "yellow";
    Chart.defaults.global.elements.point.pointHoverBorderColor = "blue";
    Chart.defaults.global.elements.point.pointHoverBorderWidth = 1;

    Chart.defaults.global.hover.intersect = false;

    var defaultLegendClickHandler = Chart.defaults.global.legend.onClick;
    var newLegendClickHandler = function (e, legendItem) {
        var index = legendItem.datasetIndex;

        if (index > 1) {
            // Do the original logic
            defaultLegendClickHandler(e, legendItem);
        } else {
            var ci = this.chart;
            [
                ci.getDatasetMeta(0),
                ci.getDatasetMeta(1)
            ].forEach(function (meta) {
                meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
            });
            ci.update();
        }
    };

    graphOptions = {
        //responsive: true,
        maintainAspectRatio: false,
        animation: {
            duration: '250'
        },
        legend: {
            display: true,
            text: 'Reading Locations',
            position: 'bottom',



            onClick: function () {
                Chart.defaults.global.legend.onClick.apply(this, arguments);
                console.log("Toggling " + sReferenceData[iCurrentStatsEntity]['__Mne'] + arguments[1].text);
                var cookieName = GetEntityRSCookieName(sReferenceData[iCurrentStatsEntity]['__Mne'], arguments[1].text);
                var index = arguments[1].datasetIndex;
                var ci = this.chart;
                var meta = ci.getDatasetMeta(index);

                // See controller.isDatasetVisible comment
                //                meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
                //if (ci.data.datasets[index].hidden) {
                if (meta.hidden) {
                    CookieAddValue(COOKIE_GL_OFF, EmptyStringIfNull($.cookie(COOKIE_GL_OFF)), cookieName);
                } else {
                    CookieRemoveValue(COOKIE_GL_OFF, EmptyStringIfNull($.cookie(COOKIE_GL_OFF)), cookieName);
                };
                ci.update();
            },

            //            onClick: cjsLegendClickHandler(),
            onHover: function (event, legendItem) {
                document.getElementById("chartCanvas").style.cursor = 'pointer';
                //var ci = this.chart;
                //var meta = ci.getDatasetMeta(legendItem.datasetIndex);
                //meta.dataset._view.borderWidth = 6;
                //ci.update();

            },
            onLeave: function (event, legendItem) {
                document.getElementById("chartCanvas").style.cursor = 'default';
                //                var ci = this.chart;
                //                var meta = ci.getDatasetMeta(legendItem.datasetIndex);
                ////                meta.dataset._view.borderWidth = 2;
                //                ci.update();
            }
        },

        title: {
            display: false,
            //text: 'TBSet'
        },
        plugins: {

            colorschemes: {

                scheme: 'brewer.Paired12'

            }

        },

        scales: {

            yAxes: [{
                ticks: {
                    max: 5,
                    min: 0,
                    stepSize: 0.5
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Metres',
                    fontSize: 20
                }
            }],
            xAxes: [{
                type: 'time',
                distribution: 'linear',
                time: {
                    unit: 'hour'
                }
            }]

        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    var label;
                    if (sUnits == Consts.sUNIT_CODE_METRIC) {
                        //                        label = data.datasets[tooltipItem.datasetIndex].label || '';

                        //                        if (label) {
                        //                            label += ': ';
                        //                        }
                        label = (Math.round(tooltipItem.yLabel * 1000) / 1000) + "m";
                    } else {
                        label = ConvertToUnits(Math.round(tooltipItem.yLabel * 1000) / 1000,
                            "F",
                            "F",
                            8);

                    }
                    return data.datasets[tooltipItem.datasetIndex].label + ' ' + label;
                },
                title: function (tooltipItem, data) {
                    return ToolTipDate(tooltipItem[0].xLabel);
                },
                labelColor: function (tooltipItem, chart) {
                    var meta = chart.getDatasetMeta(tooltipItem.datasetIndex);
                    console.log("Tis " + meta.controller._config.backgroundColor.toString());
                    return {
                        //borderColor: 'rgb(255, 255, 0)',
                        //                        backgroundColor: 'rgb(255, 0, 0)'
                        backgroundColor: meta.controller._config.backgroundColor
                    };
                }
                //labelTextColor: function (tooltipItem, chart) {
                //    return '#543453';
                //}
            }
        }
    };

}


function DrawMHRLChart(svrData, GraphLink) {
    //var canvas = document.getElementById('chartCanvas');
    //var ctx = canvas.getContext('2d');


    //var data = {
    //    //"labels": ["2019-12-02 23:00:00", "2019-12-02 23:15:00", "2019-12-02 23:30:00", "2019-12-02 23:45:00", "2019-12-03 00:00:00"],

    //    "labels": ["2019-12-02 20:00:00", "2019-12-02 20:15:00", "2019-12-02 20:30:00", "2019-12-02 20:45:00", "2019-12-02 21:00:00", "2019-12-02 21:15:00", "2019-12-02 21:30:00", "2019-12-02 21:45:00", "2019-12-02 22:00:00", "2019-12-02 22:15:00", "2019-12-02 22:30:00", "2019-12-02 22:45:00", "2019-12-02 23:00:00", "2019-12-02 23:15:00", "2019-12-02 23:30:00", "2019-12-02 23:45:00", "2019-12-03 00:00:00"], "datasets": [{ "label": "VYR", "data": [0.373, 0.374, 0.374, 0.373, 0.375, 0.375, 0.373, 0.375, 0.375, 0.375, 0.374, 0.373, 0.373, 0.375, 0.373, 0.375, 0.374] }, { "label": "PON", "data": [0.419, 0.418, 0.419, 0.418, 0.419, 0.417, 0.419, 0.418, 0.417, 0.418, 0.417, 0.418, 0.417, 0.417, 0.417, 0.417, 0.417] }, { "label": "MEI", "data": [0.491, 0.491, 0.49, 0.493, 0.493, 0.491, 0.491, 0.489, 0.491, 0.493, 0.491, 0.489, 0.49, 0.492, 0.493, 0.49, 0.491] }, { "label": "LLA", "data": [0.885, 0.884, 0.883, 0.882, 0.882, 0.882, 0.882, 0.882, 0.882, 0.882, 0.882, 0.881, 0.88, 0.88, 0.879, 0.879, 0.879] }]
    //};

    // Notice the scaleLabel at the same level as Ticks

    // Chart declaration:
    //var myBarChart = new Chart(ctx, {
    //    type: 'line',
    //    data: svrData,
    //    options: options
    //});
    //myBarChart.Line(svrData, options);
    //myBarChart.data = svrData;
    //myBarChart.options = options;po
    //myBarChart.update();

    //if (graphOptions === undefined) {
    //    SetGraphDefaults();
    //};
    var cookieName;

    myBarChart.data.labels = svrData.labels;
    //TODO: Call SetGraphUnits here to return the modified Feet / Inches datatset 
    myBarChart.options.scales.yAxes[0].scaleLabel.labelString = (sUnits == Consts.sUNIT_CODE_METRIC) ? "Metres" : "Feet";
    myBarChart.data.datasets = svrData.datasets;
    // Convert to imperial if needed;
    if (sUnits == Consts.sUNIT_CODE_IMPERIAL) {
        GetGraphImperialUnits(svrData);
    }
    var lineOffCookie = $.cookie(COOKIE_GL_OFF);
    for (var i = 0; i < (myBarChart.data.datasets.length); i++) {
        cfgName = GetEntityRSCookieName(sReferenceData[iCurrentStatsEntity]['__Mne'], myBarChart.data.datasets[i].label);
        if (StringContainsDelimitedValue(lineOffCookie, cfgName)) {
            myBarChart.data.datasets[i].hidden = true;
        }
    }
    //    myBarChart.options = graphOptions;
    myBarChart.options.scales.yAxes[0].ticks = svrData.ticks;
    myBarChart.options.scales.yAxes[0].ticks.precision = 0;
    myBarChart.options.scales.yAxes[0].ticks.stepSize = undefined;

    myBarChart.options.scales.xAxes[0].time.unit = ((GraphLink == 1) ? "hour" : "day");
    myBarChart.update();

}


// $$

function ShowGraph(GraphLink) {

    location.hash = window.pageYOffset;
    //        location.reload(); 
    //        alert(location.hash);
    //TODO: $$$ Alternative Loading GIF
    //    $("#stats_graph_img").attr("src", "/loading.gif").addClass('loading');
    console.log("Graph Click detected");

    var folderName = "Graphs_" + $('#master_CPHbody_txtGraphFolderNo').val();

    //        var mnemonic = $('#stats_Mnemonic').html();
    var mnemonic = StringExtractDelimitedSubstring($('#stats_Mnemonic').html(), '(', ')');
    var fileName = "RefData/" + folderName + "/LatestReadings_" + mnemonic;

    // toggle the highlight to the selected button and setup the image filename
    console.log("****** Graph link is " + GraphLink);
    if (GraphLink == 1) {
        //if (sUnits == Consts.sUNIT_CODE_METRIC) {
        fileName += "_Last24Hours.JSON";
        //}
        //else {
        //    fileName += "_Last24HoursImp.JSON";
        //}
        $('#G1').children("a:first").addClass('highlight');
        $('#G2').children("a:first").removeClass('highlight');
        //console.log ("Showing last 24 hours ", fileName);
    } else if (GraphLink == 2) {
        //if (sUnits == Consts.sUNIT_CODE_METRIC) {
        fileName += "_Last7Days.JSON";
        //}
        //else {
        //    fileName += "_Last7DaysImp.JPEG";
        //}
        $('#G1').children("a:first").removeClass('highlight');
        $('#G2').children("a:first").addClass('highlight');
        //console.log ("Showing last 7 days ", fileName);
    }

    //console.log (fileName);
    //console.log ("Current Graph is ", iCurrentGraph);
    if ((fileName !== currentChartFilename) || (currentGraphLink !== GraphLink) || (currentUnits !== sUnits)) {
        //TODO: $$$ Alternative Loading GIF

        $.getJSON(fileName,
            function (data) {
                DrawMHRLChart(data, GraphLink);
            })
            .done(function () { $('#stats_graph').show(); })
            .fail(function () { $('#stats_graph').hide(); });
        currentChartFilename = fileName;
        currentGraphLink = GraphLink;
        currentUnits = sUnits;

    }

    //$("#stats_graph_img").attr("src", fileName).removeClass('loading');

}

function ShowDefaultGraph() {

    var iGraph;
    console.log("ShowDefaultGraph called");
    iGraph = ZeroIfNull($.cookie(COOKIE_DG));
    if (iGraph == 0) {
        iGraph = 1;
    }
    ShowGraph(iGraph);
}

function SetEntityListHighlight(EntityIndex, HighlightOn) {
    // Add or remove the 'highlight' class to/from the given EntityList item
    // and its associated List Item from the Favourites list if it exists

    console.log(TimedConsoleLog("SetEntityListHighlight ") + EntityIndex + ", " + HighlightOn);
    var sRiverTag = Consts.sENTITY_TAG + EntityIndex;
    // var sFavRiverTag = Consts.sFAV_ENTITY_TAG + EntityIndex;
    if (HighlightOn == true) {
        $(sRiverTag).children("li:first").addClass('highlight');
        $(sRiverTag).addClass('highlight');
        //$(sFavRiverTag).addClass('highlight');
    }
    else {
        $(sRiverTag).children("li:first").removeClass('highlight');
        $(sRiverTag).removeClass('highlight');
        //$(sFavRiverTag).removeClass('highlight');
    }
    TimedConsoleLog("SetEntityListHighlight END");
}

function TryThis(EntityIndex) {

    //    console.log("Trying it for entity " + EntityIndex.toString);

    if (GOOGLE_ON === 1) {
        mapCentre = map.getCenter();
        mapZoom = map.getZoom();
        lat = mapCentre.lat();
        lng = mapCentre.lng();
    }
    else if (OS_ON == 1) {
        mapCentre = map.getCenter();
        mapZoom = map.getZoom();
        lat = mapCentre.lat;
        lng = mapCentre.lon;
    }
    //    alert(lat + "," + lng + "," + mapZoom);

    window.prompt("Copy to clipboard: Ctrl+C, Enter",
        "UPDATE Entity SET LocalMapLongitude = " + lat + ", LocalMapLatitude = " + lng + ", LocalMapZoomLevel = " + mapZoom +
        " WHERE Mnemonic = '" + sReferenceData[iCurrentStatsEntity]['__Mne'] + "';");

    //    window.prompt("Copy to clipboard: Ctrl+C, Enter",
    //        "UPDATE Entity SET LocalMapLongitude = " + lat + ", LocalMapLatitude = " + lng + ", LocalMapZoomLevel = 3" +
    //        " WHERE Mnemonic = '" + sReferenceData[iCurrentStatsEntity]['__Mne'] + "';");

    //    window.prompt("Copy to clipboard: Ctrl+C, Enter",
    //        "UPDATE Entity SET LocalMapLongitude = " + lat + ", LocalMapLatitude = " + lng + ", LocalMapZoomLevel = " + mapZoom +
    //        " WHERE Mnemonic = '" + sReferenceData[iCurrentStatsEntity]['__Mne'] + "';");

    //    window.prompt("Copy to clipboard: Ctrl+C, Enter",
    //        "UPDATE Entity SET LocalMapLatitude = " + lat + ", LocalMapLongitude = " + lng + ", LocalMapZoomLevel = " + mapZoom + mapCentre.lonlat + 
    //        " WHERE Mnemonic = '" + sReferenceData[EntityIndex]['__Mne'] + "';");

}

function TryThis2(EntityIndex) {

    //    console.log("Trying it for entity " + EntityIndex.toString);

    //  ** Test for a polyline colour setting
    for (var i = 0, ilimit = arrPolyFeature[EntityIndex].length; i < ilimit; i++) {
        //        Stempthing2 = VectorLayer.getFeaturesByAttribute('EntityID', parseInt(EntityIndex));
        //        console.log("it is :" + EntityIndex + "_" + i);
        polyFeature = VectorLayer.getFeaturesByAttribute('PolySeg', EntityIndex + "_" + i);
        //        arrPolyFeature[EntityIndex][i].style.strokeColor = "#FFFFFF";
        polyFeature[0].style.strokeColor = "#666666";
        //        VectorLayer.removeFeatures(VectorLayer.getFeaturesByAttribute('EntityID', parseInt(EntityIndex)));
        VectorLayer.redraw();
    }


    eFeature = EntityLayer.getFeaturesByAttribute('EntityID', EntityIndex);
    eFeature[0].renderIntent = "select";
    //    eFeature[0].layer.drawFeature(eFeature);
    EntityLayer.redraw();
    //    eFeature.layer.drawFeature(eFeature);


}

function MarkEntityElements(EntityIndex, HighlightOn, useZIndex) {
    // A Mouseover or Mouseout event has occurred on either an Entity List item, its associated polyline,
    // or associated marker.
    // Change the display characteristics of the Entity with the given EntityIndex depending on the 
    // setting of the HighlightOn boolean
    // Set the associated Polyline segments to the Highlight colour or to the various colour depending on state
    // Set the associated Entity Marker image depending on highlight
    // Set the set of RS Markers as appropriate depending on highlight
    // Set both Entity and RS Markers with the given zIndex. An Entity losing focus should receive
    // zIndex 999 where as one gaining focus should receive zIndex 1000

    var objPolyColour = { strokeColor: "#666666" };     // Placeholder : Value will be changed as appropriate later
    var sRiverTag = Consts.sENTITY_TAG + EntityIndex;
    var thisPolyColourArray;
    var EntityImg;
    var objzIindex;

    console.log(TimedConsoleLog("MarkEntityElements called with : "), EntityIndex, HighlightOn)
    //console.log (arrEntityMarker[EntityIndex]);

    if (HighlightOn == true) {
        // Highlight the EntityList item itself
        SetEntityListHighlight(EntityIndex, HighlightOn);

        if (MAPS_ON == 1) {
            if (GOOGLE_ON == 1) {
                EntityImg = arrEntityImages[EntityIndex]['ImgFocus'];
                console.log("Entity Highlight ON with image ", EntityImg);
            }
            else if (OS_ON == 1) {
                OSRenderIntent = "select";
            }
        }
        // Set appropriate zIndex
        //            objzIndex = {zIndex:1000};
    }
    else {
        //            $(sRiverTag).removeClass('highlight');
        if (MAPS_ON == 1) {
            if (GOOGLE_ON == 1) {
                EntityImg = arrEntityImages[EntityIndex]['ImgPlain'];
                console.log("Entity Highlight OFF with image ", EntityImg);
            }
            else if (OS_ON == 1) {
                OSRenderIntent = "default";
            }
        }
        // Set appropriate zIndex
        //            objzIndex = {zIndex:55};
    }

    // Adjust the marker image to highlighted/non highlighted and make it visible

    if ((MAPS_ON == 1) && (sReferenceData[EntityIndex]['__MO'] == 1)) {
        // If this is a mouseover event, we are highlighting all polyline segments for this entity
        // with the same colour
        //+_+
        objPolyColour.strokeColor = Consts.sPOLY_COLOUR_WITH_FOCUS;
        thisPolyColourArray = sDynamicData[EntityIndex]['PolyColour'];

        if (GOOGLE_ON == 1) {
            if ((bLabellingAllRivers || bLabellingSelected)) {

                // ** Entity Marker
                arrEntityMarker[EntityIndex].setIcon(EntityImg);
                arrEntityMarker[EntityIndex].setVisible(true);
            }
            //        arrEntityMarker[EntityIndex].setZIndex(objzIndex);  // ^^ To sort out. This currently
            // produces a failure

            // ** Polylines

            // Note that there should always be one more PolyLine Section than there are Reading Stations and
            // hence RSData entries. This is because s ReadingStation StateColourVal is used to denote 
            // the colour of the river from that Reading Station and upstream. Hence when you get to the last 
            // Reading Station it is used to denote the state both above and below this Reading Station. Hence, 
            // the need for a PolyColour array rather than using the StateColourVal in the RSData field.

            for (var i = 0, ilimit = thisPolyColourArray.length; i < ilimit; i++) {
                if (HighlightOn == false) {
                    //+_+
                    objPolyColour.strokeColor = ConvertColourNum(thisPolyColourArray[i]);
                }

                // Adjust all Polyline segments belonging to this Entity as appropriate
                if (MAPS_ON == 1) {
                    //+_+
                    arrPolyLines[EntityIndex][i].setOptions(objPolyColour);
                }
                //console.log("Setting Poly Segment for entity/segment to colour ", EntityIndex, i, objPolyColour.strokeColor);
            }
        }
        else if (OS_ON == 1) {
            if ((bLabellingAllRivers || bLabellingSelected)) {

                // ** Entity Marker
                TimedConsoleLog("Pre get attr by feature");
                eFeature = EntityLayer.getFeaturesByAttribute('EntityID', parseInt(EntityIndex));
                TimedConsoleLog("Post get attr by feature");
                eFeature[0].renderIntent = OSRenderIntent;
                //    eFeature[0].layer.drawFeature(eFeature);
                TimedConsoleLog("Post render set");
                EntityLayer.redraw();
                TimedConsoleLog("Post redraw");

                // Add the current Entity to the layer selEntityLayer

                //                selEntityLayer.addFeatures(new OpenLayers.Feature.Vector(new OpenLayers.Geometry.Point(parseInt(sReferenceData[i]['Ent__EA']),
                //                            parseInt(sReferenceData[i]['Ent__NO'])),
                //                            { lbl: sReferenceData[i]['Name'], EntityID: i }));

                selEntityLayer.addFeatures(eFeature);
                if (bLabellingSelected) {
                    selEntityLayer.redraw();
                }

                //                arrEntityMarker[EntityIndex].setIcon(EntityImg);
                //                arrEntityMarker[EntityIndex].setVisible(true);

                //$^$ TAKE A LOK AT ENABLING THESE AGAIN
                //                arrEntityMarker[EntityIndex].setUrl(EntityImg);
                //                arrEntityMarker[EntityIndex].display (true);
                //$^$ TAKE A LOK AT ENABLING THESE AGAIN

            }

            // ** Polylines

            // Note that there should always be one more PolyLine Section than there are Reading Stations and
            // hence RSData entries. This is because s ReadingStation StateColourVal is used to denote 
            // the colour of the river from that Reading Station and upstream. Hence when you get to the last 
            // Reading Station it is used to denote the state both above and below this Reading Station. Hence, 
            // the need for a PolyColour array rather than using the StateColourVal in the RSData field.

            TimedConsoleLog("doing the poly colour array ");

            for (var i = 0, ilimit = thisPolyColourArray.length; i < ilimit; i++) {

                // Adjust all Polyline segments belonging to this Entity as appropriate
                if (MAPS_ON == 1) {

                    if (HighlightOn == false) {
                        //+_+
                        objPolyColour.strokeColor = ConvertColourNum(thisPolyColourArray[i]);
                        objPolyColour.strokeWidth = iDefaultStrokeWidth;
                    }
                    else {
                        objPolyColour.strokeWidth = iDefaultStrokeWidth + 6;
                    }

                    console.log(TimedConsoleLog("get feature "), i);
                    polyFeature = VectorLayer.getFeaturesByAttribute('PolySeg', EntityIndex + "_" + i);
                    console.log(TimedConsoleLog("stroke colour "), i);
                    polyFeature[0].style.strokeColor = objPolyColour.strokeColor;
                    polyFeature[0].style.strokeWidth = objPolyColour.strokeWidth;
                    //+_+
                }
                //console.log ("Setting Poly Segment for entity/segment to colour ", EntityIndex, i, objPolyColour.strokeColor);
            }
            TimedConsoleLog("End of bits now the redraw");
            console.log(TimedConsoleLog("redraw "), i);
            VectorLayer.redraw();
            console.log(TimedConsoleLog("redraw end"), i);
            TimedConsoleLog("doing the poly colour array done");
        }
    }


    if ((MAPS_ON == 1) && (SHOW_MAP_RS == 1) && (sReferenceData[EntityIndex]['__MO'] == 1)) {
        //TimedConsoleLog("doing RS Images");
        if (bLabellingAllRivers || bLabellingSelected) {
            // Mark all Reading Station markers as appropriate
            for (var i = 0, ilimit = arrEntityRSImages[EntityIndex].length; i < ilimit; i++) {
                // ^^ $$$ Change this to call HighlightRSMarker
                if (HighlightOn == true) {
                    EntityImg = arrEntityRSImages[EntityIndex][i]['ImgFocus'];
                    //console.log ("RS Highlight ON entity/RS with image ", EntityIndex, i, EntityImg);
                }
                else {
                    EntityImg = arrEntityRSImages[EntityIndex][i]['ImgPlain'];
                    //console.log ("RS Highlight OFF entity/RS with image ", EntityIndex, i, EntityImg);
                }
                // Adjust the RS Marker image to highlighted/non highlighted and make it visible
                //+_+
                if (MAPS_ON == 1) {
                    if (GOOGLE_ON == 1) {
                        // ** RS Marker
                        arrRSMarker[EntityIndex][i].setIcon(EntityImg);
                        arrRSMarker[EntityIndex][i].setVisible(true);
                    }
                    else if (OS_ON == 1) {

                        // ** RS Marker
                        /*                        
                                                rsFeature = EntityLayer.getFeaturesByAttribute('EntityID', parseInt(EntityIndex));
                                                rsFeature[0].renderIntent = OSRenderIntent;
                                                //    eFeature[0].layer.drawFeature(eFeature);
                                                EntityLayer.redraw();
                        */
                        //console.log(TimedConsoleLog("Updating for "), EntityIndex);
                        //console.log(TimedConsoleLog("Image is "), EntityImg);

                        arrRSMarker[EntityIndex][i].setUrl(EntityImg);
                        arrRSMarker[EntityIndex][i].display(true);

                    }
                }
            }
        }
    }


    //TimedConsoleLog("Stats Entries");
    // If highlighting is being removed, remove any highlight from the Stats Entries
    if (SHOW_STATS == 1) {
        if (HighlightOn == false) {
            $(".stats_row_top").removeClass('highlight');
            $(".stats_row_bot").removeClass('highlight');
        }

        // Update the stats panel with details of Reading Stations for the current Entity
        ShowEntityStats(EntityIndex);
        // Reset the "previously selected Reading Station" (since none are now highlighted)
        iPrevRSNum = Consts.NotDefined

    }

    if (HighlightOn == false)
    // If we were turning a highlight off, note that no timer is now running to restore Entity normal video
    {
        console.log(TimedConsoleLog("Highlight off ditching timer"), iTimerEntityHighlight);
        iTimerEntityHighlight = Consts.NotDefined;
    }

    //console.log(TimedConsoleLog("MarkEntityElements END"), iTimerEntityHighlight);
};

function ShowHideEntityRSMarkers(EntityIndex, ShowMarkers) {
    // Show or hide the RS Markers of the given EntityIndex
    if ((MAPS_ON == 1) && (SHOW_MAP_RS == 1) && (sReferenceData[EntityIndex]['__MO'] == 1)) {
        if (GOOGLE_ON == 1) {
            for (var i = 0, ilimit = arrEntityRSImages[EntityIndex].length; i < ilimit; i++) {
                //+_+
                arrRSMarker[EntityIndex][i].setVisible(ShowMarkers);
            }
        }
        else if (OS_ON == 1) {

            for (var i = 0, ilimit = arrEntityRSImages[EntityIndex].length; i < ilimit; i++) {
                //+_+
                arrRSMarker[EntityIndex][i].display(ShowMarkers);
            }
        }
    }
}

function HideMarkers(EntityIndex) {
    // Hide the Entity Markers and all RS Markers for the given Entity

    //console.log(TimedConsoleLog("HideMarkers called with : "), EntityIndex)

    if ((MAPS_ON == 1) && (sReferenceData[EntityIndex]['__MO'] == 1)) {
        // Hide the Entity Marker unless the user has selected to label all rivers
        if (!bLabellingAllRivers) {
            //+_+
            if (GOOGLE_ON == 1) {
                arrEntityMarker[EntityIndex].setVisible(false);
            }
        }

        if (SHOW_MAP_RS == 1) {
            ShowHideEntityRSMarkers(EntityIndex, false);
        }
        if (OS_ON == 1) {
            EntityLayer.redraw();
            selEntityLayer.removeAllFeatures();
        }
    }
    //TimedConsoleLog("HideMarkers END ")
};

function ShowHideEntities(ShowHideAll, ShowHideSelected) {
    // Show/Hide the Entity Markers of all entities, and of the selected entity respectively

    //console.log(LogTime() + " ShowHideEntities called with : ", ShowHideAll, ShowHideSelected)
    if (MAPS_ON == 1) {
        if (GOOGLE_ON == 1) {
            for (var i = 0, ilimit = arrEntityMarker.length; i < ilimit; i++) {

                // Hide or display all but the currently selected marker which should stay as it is
                //+_+
                if (sReferenceData[i]['__MO'] == 1) {
                    arrEntityMarker[i].setVisible(ShowHideAll);
                }
            }

            //+_+
            arrEntityMarker[iCurrentStatsEntity].setVisible(ShowHideSelected);
        }
        else if (OS_ON == 1) {
            EntityLayer.setVisibility(ShowHideAll);
            selEntityLayer.setVisibility(ShowHideSelected);
            //            EntityLayer.redraw();
        }
        ShowHideEntityRSMarkers(iCurrentStatsEntity, ShowHideSelected);
    }
    bLabellingAllRivers = ShowHideAll;
    bLabellingSelected = ShowHideSelected;
}


function HighlightRSMarker(EntityIndex, RSIndex, HighlightOn) {
    // Set the given individual RS Marker to its plain or focus image
    var EntityImg;

    //console.log("************ HighlightRSMarker called ", EntityIndex, RSIndex, HighlightOn);
    if (HighlightOn == true) {
        EntityImg = arrEntityRSImages[EntityIndex][RSIndex]['ImgFocus'];
        //console.log ("RS Highlight ON entity/RS with image ", EntityIndex, RSIndex, EntityImg);
    }
    else {
        EntityImg = arrEntityRSImages[EntityIndex][RSIndex]['ImgPlain'];
        //console.log ("RS Highlight OFF entity/RS with image ", EntityIndex, RSIndex, EntityImg);
    }
    // Adjust the RS Marker image to highlighted/non highlighted
    if ((MAPS_ON == 1) && (sReferenceData[EntityIndex]['__MO'] == 1)) {
        if (GOOGLE_ON == 1) {
            //+_+
            arrRSMarker[EntityIndex][RSIndex].setIcon(EntityImg);
        }
        else if (OS_ON == 1) {
            arrRSMarker[EntityIndex][RSIndex].setUrl(EntityImg);
        }
    }
    if (HighlightOn == false)
    // If we were turning a highlight off, note that no timer is now running to restore Entity normal video
    {
        iTimerRSHighlight = Consts.NotDefined;
    }

}

function ForceEntityResetTimeout(iTimerID, EntityNum) {
    // Cancel the existing timer with the given ID and call MarkEntityElements immediately
    // with the given EntityNum to turn highlighting off.
    // i.e. force the timer to expire in effect.

    //console.log ("FOrceEntityReset called with : ", iTimerID, EntityNum);
    clearTimeout(iTimerID);
    // Mark the timer ID as undefined again
    iTimerEntityHighlight = Consts.NotDefined;

    MarkEntityElements(EntityNum, false);
}

function ForceRSResetTimeout(iTimerID, EntityIndex, RSIndex) {
    // Cancel the existing timer with the given ID and call HighlightRSMarker immediately
    // with the given RSIndex to turn highlighting off.
    // i.e. force the timer to expire in effect.

    clearTimeout(iTimerID);
    // Mark the timer ID as undefined again
    iTimerRSHighlight = Consts.NotDefined;

    HighlightRSMarker(EntityIndex, RSIndex, false);
}

function EntityMouseover(EntityNum) {
    // A Mouseover of either an Entity List item, its associated polyline or associated marker.
    // has taken place. 
    // If another entity was previously highlighted, hide those elements first (however if all
    // Entity Markers are currently being displayed, leave the previous entity marker visible
    // Then highlight the new entity objects.
    // Calls MarkEntityElements to do the work, passing the given Element number and Highlight parameter
    // Set the "Last Selected Entity" Cookie value

    //console.log(TimedConsoleLog("EntityMouseover called for "), EntityNum);

    if ((iPrevEntityNum == EntityNum) && (iTimerEntityHighlight != Consts.NotDefined))
    // If the same Entity as was previously highlighted is being highlighted again
    // and the timer request to return it to normal video is still running, cancel and restart it
    {
        //console.log(TimedConsoleLog("Same mouseover as before"), iPrevEntityNum);
        clearTimeout(iTimerEntityHighlight);
        //+_+
        iTimerEntityHighlight = setTimeout(function () { MarkEntityElements(EntityNum, false); }, Consts.sENTITY_HIGHLIGHT_TIME_MS);
        return;
    };

    if (iPrevEntityNum != EntityNum)
    // Otherwise, if a different return the previous entity to normal state
    {
        if ((iTimerEntityHighlight != Consts.NotDefined) && (iPrevEntityNum != Consts.NotDefined)) {
            console.log(TimedConsoleLog("EM ForceEReset"), iTimerEntityHighlight);
            ForceEntityResetTimeout(iTimerEntityHighlight, iPrevEntityNum)
        };

        if (iPrevEntityNum != Consts.NotDefined) {
            // Now hide the Entity and RS Markers
            TimedConsoleLog("EM Hiding markers");
            HideMarkers(iPrevEntityNum);
            // including the EntityList item itself
            SetEntityListHighlight(iPrevEntityNum, false);
        }

    }

    //TimedConsoleLog("Pre Mark Ent Els 123");
    // Now highlight the current Entity and set the reset timer again
    MarkEntityElements(EntityNum, true);
    //TimedConsoleLog("Post Mark Ent Els");
    //+_+
    iTimerEntityHighlight = setTimeout(function () { MarkEntityElements(EntityNum, false); }, Consts.sENTITY_HIGHLIGHT_TIME_MS);
    //console.log(TimedConsoleLog("Timer entry id is "), iTimerEntityHighlight);
    //console.log(iTimerEntityHighlight);
    iPrevEntityNum = EntityNum;
    // Set a cookie to denote the number of the Entity just selected

    //TimedConsoleLog("Cookie set");
    $.cookie(COOKIE_ENTITY_LAST_SELECT, EntityNum.toString(), COOKIE_DO_NOT_EXPIRE);

    //TimedConsoleLog("EntityMouseover ended for ");
}

function ShowCookieNote() {

    try {
        if ($.cookie(COOKIE_WE_USE_COOKIES_NOTE) == null) {
            console.log("Showing Cookie Help");
            $('div#pop-upCookies').show();

            var date = new Date();
            date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));
            $.cookie("example", "foo", { expires: date });
            $.cookie(COOKIE_WE_USE_COOKIES_NOTE, "Y", { expires: date });
        }
        //        else {
        //            console.log("Not Showing Cookie Help");
        //        }
    }
    catch (cookieErr) {
    }
}


function SetPolyWidths(useWidth) {
    if (OS_ON == 1) {
        for (var i = 0; i < VectorLayer.features.length; i++) {
            VectorLayer.features[i].style.strokeWidth = useWidth;
        }
        iDefaultStrokeWidth = useWidth;
    }
}

function ShowHelp() {
    // If the general note from the server is not the emptry string and there's no cookie
    // to denote that it has been recently seen, then display it.
    //    alert($('#pop-upHelpContent').html().length);
    //    alert($.cookie(COOKIE_GENERAL_NOTE));
    //    alert("[" + $('#pop-upHelpContent').html() + "]");
    //    alert("[" + $('#pop-upHelpContent').html().trim() + "]");
    if (($('#pop-upHelpContent').html().trim().length != 0) && ($.cookie(COOKIE_GENERAL_NOTE)) == null)
        if (($('#pop-upHelpContent').html().length != 0)) {
            console.log("Showing Help");
            $('div#pop-upHelp').show();
            $.cookie(COOKIE_GENERAL_NOTE, "Y", { expiresAt: new Date(new Date().getTime() + 24 * 60 * 60 * 1000) });
        }
        else {
            //console.log("Not Showing Help");
            //    alert("Leaving ShowHelp");
        }
}

function updateTwitterValues(share_url, title) {
    // clear out the <a> tag that's currently there...probably don't really need this since you're replacing whatever is in there already.
    $('#twitter-share-section').html('&nbsp;');
    $('#twitter-share-section').html('<a href="https://twitter.com/share" class="twitter-share-button" data-via="RiverMonitor" data-url="' + share_url + '" data-text="' + title + '" data-count="none">Tweet</a>');
    //$('#twitter-share-section').html('<a href="https://twitter.com/share" class="twitter-share-button" data-via="RiverMonitor" data-url="' + share_url + '" data-size="large" data-text="' + title + '" data-count="none">Tweet</a>');
    twttr.widgets.load();
}

function setTitleAndLinks() {
    var thisName = sReferenceData[iCurrentStatsEntity]['Name'];

    var LocTitle = "";
    var LocPage = "";

    //    if (!typeof sReferenceData[iCurrentStatsEntity]['Loc'] == 'undefined') {
    if (!sReferenceData[iCurrentStatsEntity]['Loc'] == '') {
        LocTitle = " (" + sReferenceData[iCurrentStatsEntity]['Loc'] + ")";
        LocPage = "/" + sReferenceData[iCurrentStatsEntity]['Loc'];
    }
    document.title = "River " + thisName + LocTitle + " Levels, Map & Graphs";

    $('meta[name=description]').attr('content', 'River ' + thisName + LocTitle + ' Levels, Map & Graphs, River Level Graphs (Last 24 Hours, Last 2 Weeks) & Trends');

    //    Request.QueryString["River"] + " River Levels, Map & Graphs";
    // Resintate if Twitter and FaceBook context needs to be maintained
    //TODO: $$$ Uncomment if Twitter and Facebook come back in
    //updateTwitterValues("http://www.rivermonitor.co.uk/" + thisName + LocPage, document.title);

    //setFBUrl("http://www.rivermonitor.co.uk/" + thisName + LocPage);

}


function setFBUrl(url) {

    sUrl = url;

    //    url = "http://www.facebook.com/plugins/like.php?href=" + sUrl + "&layout=button&amp;action=like&amp;show_faces=false&amp;share=true&amp;height=35";
    //    url = "http://www.facebook.com/plugins/like.php?href=" + sUrl + "&layout=button_count&show_faces=false&width=400&action=like&font=arial&colorscheme=light";
    document.getElementById('faceLike').setAttribute('src', url);
    //alert("url : "+url);

    $('#socmed1').html('&nbsp;');
    $('#socmed1').html('<iframe id="faceLike" src="//www.facebook.com/plugins/like.php?href=' + url + '&amp;width=90&amp;layout=button&amp;action=like&amp;show_faces=false&amp;share=true&amp;height=35" scrolling="no" frameborder="0" style="border:none; overflow:hidden; width:90px; height:35px;" allowTransparency="true"></iframe>');
    //    $('#socmed1').html('<iframe id="faceLike" src="//www.facebook.com/plugins/like.php?href=http%3A%2F%2Fwww.rivermonitor.co.uk&amp;width=90&amp;layout=button&amp;action=like&amp;show_faces=false&amp;share=true&amp;height=35" scrolling="no" frameborder="0" style="border:none; overflow:hidden; width:90px; height:35px;" allowTransparency="true"></iframe>');

}

function LLtoNE(lat, lon) {
    // From http://www.dorcus.co.uk/carabus/ll_ngr.html
    var deg2rad = Math.PI / 180;
    var rad2deg = 180.0 / Math.PI;
    var phi = lat * deg2rad;      // convert latitude to radians
    var lam = lon * deg2rad;   // convert longitude to radians
    a = 6377563.396;       // OSGB semi-major axis
    b = 6356256.91;        // OSGB semi-minor axis
    e0 = 400000;           // OSGB easting of false origin
    n0 = -100000;          // OSGB northing of false origin
    f0 = 0.9996012717;     // OSGB scale factor on central meridian
    e2 = 0.0066705397616;  // OSGB eccentricity squared
    lam0 = -0.034906585039886591;  // OSGB false east
    phi0 = 0.85521133347722145;    // OSGB false north
    var af0 = a * f0;
    var bf0 = b * f0;
    // easting
    var slat2 = Math.sin(phi) * Math.sin(phi);
    var nu = af0 / (Math.sqrt(1 - (e2 * (slat2))));
    var rho = (nu * (1 - e2)) / (1 - (e2 * slat2));
    var eta2 = (nu / rho) - 1;
    var p = lam - lam0;
    var IV = nu * Math.cos(phi);
    var clat3 = Math.pow(Math.cos(phi), 3);
    var tlat2 = Math.tan(phi) * Math.tan(phi);
    var V = (nu / 6) * clat3 * ((nu / rho) - tlat2);
    var clat5 = Math.pow(Math.cos(phi), 5);
    var tlat4 = Math.pow(Math.tan(phi), 4);
    var VI = (nu / 120) * clat5 * ((5 - (18 * tlat2)) + tlat4 + (14 * eta2) - (58 * tlat2 * eta2));
    east = e0 + (p * IV) + (Math.pow(p, 3) * V) + (Math.pow(p, 5) * VI);
    // northing
    var n = (af0 - bf0) / (af0 + bf0);
    var M = Marc(bf0, n, phi0, phi);
    var I = M + (n0);
    var II = (nu / 2) * Math.sin(phi) * Math.cos(phi);
    var III = ((nu / 24) * Math.sin(phi) * Math.pow(Math.cos(phi), 3)) * (5 - Math.pow(Math.tan(phi), 2) + (9 * eta2));
    var IIIA = ((nu / 720) * Math.sin(phi) * clat5) * (61 - (58 * tlat2) + tlat4);
    north = I + ((p * p) * II) + (Math.pow(p, 4) * III) + (Math.pow(p, 6) * IIIA);
    east = Math.round(east);       // round to whole number
    north = Math.round(north);     // round to whole number
    nstr = String(north);      // convert to string
    estr = String(east);       // ditto
}


function ConvertToUnits(sSourceLevel,
    sFromUnitCode,
    sToUnitCode,
    LCD) {

    var sReturnString = '';
    var bReadingPunctuation = true;
    var sFEET_SINGLE_INDICATOR_WORDS = " foot";
    var sINCHES_SINGLE_INDICATOR_WORDS = " inch";
    var sFEET_PLURAL_INDICATOR_WORDS = " feet";
    var sINCHES_PLURAL_INDICATOR_WORDS = " inches";
    var sFEET_SINGLE_INDICATOR_SYMBOLS = "'";
    var sINCHES_SINGLE_INDICATOR_SYMBOLS = "\\\"";
    var sFEET_PLURAL_INDICATOR_SYMBOLS = "'";
    var sINCHES_PLURAL_INDICATOR_SYMBOLS = "\\\"";

    // If the passed value is not a valid floating number, return "-"
    try {
        // Convert the value as necessary
        //if (sFromUnitCode == sToUnitCode) {
        //    sReturnString += sSourceLevel;
        //}
        //else
        if ((sFromUnitCode != "F") || ((sToUnitCode != "F") && (sToUnitCode != "I"))) {
            sReturnString += sSourceLevel + "!";
        }
        else {
            var fToValue = sSourceLevel * 12;
            var sInches, sInchesFraction, sInchesFractionFinal;
            var sFEET_SINGLE_INDICATOR, sFEET_PLURAL_INDICATOR, sINCHES_SINGLE_INDICATOR, sINCHES_PLURAL_INDICATOR;
            var iVal, iVal2, iFeet, iValNumerator, iValDenominator;
            var bContinue;

            // Determine which units markers should be used
            if (bReadingPunctuation) {
                sFEET_SINGLE_INDICATOR = sFEET_SINGLE_INDICATOR_WORDS;
                sFEET_PLURAL_INDICATOR = sFEET_PLURAL_INDICATOR_WORDS;
                sINCHES_SINGLE_INDICATOR = sINCHES_SINGLE_INDICATOR_WORDS;
                sINCHES_PLURAL_INDICATOR = sINCHES_PLURAL_INDICATOR_WORDS;
            }
            else {
                sFEET_SINGLE_INDICATOR = sFEET_SINGLE_INDICATOR_SYMBOLS;
                sFEET_PLURAL_INDICATOR = sFEET_PLURAL_INDICATOR_SYMBOLS;
                sINCHES_SINGLE_INDICATOR = sINCHES_SINGLE_INDICATOR_SYMBOLS;
                sINCHES_PLURAL_INDICATOR = sINCHES_PLURAL_INDICATOR_SYMBOLS;
            }

            // Note: This routine was originally converted from javascript and used Javascript prototypes
            // in order to convert between any units as set up in the following data structures

            //var LengthOb = ({inch:12, inches:12, foot:1, feet:1, yard:0.3333333333333333, mile:0.00018938676565281618, miles:0.00018938676565281618, fathom:0.16666666666666666, nauticalMiles:0.868421, nanometre:30479999.02464003, micron:304799.9902464003, micrometre:304799.9902464003, millimetre:304.7999902464003, centimetre:30.47999902464003, metre:0.3047999902464003, kilometre:0.0003047999902464003})
            //Number.prototype.weight=function (s, e) {return (this * WeightOb[s]) / WeightOb[e];}
            //Number.prototype.distance = function (s, e) {return (this * LengthOb[e]) / LengthOb[s];}

            //fToValue = fValue.distance("metre","inch");

            // $^^ This C# routine currently only supports meters to inches hence the above
            // still needs to be implemented if other alternatives are required

            // The value is already in Feet (decimal)

            //            fToValue = fValue * 12 / 0.3047999902464003;

            // Work out the number of feet. 
            iFeet = Math.floor(fToValue / 12);
            iVal = iFeet;

            // reduce it to the remaining inches in decimal
            fToValue -= (iVal * 12);

            // Work out the ROUNDED number of inches in terms of its lowest common denominator. e.g. 5.5 expressed in
            // halves would be 11.04 i.e. 5.52 x 2
            iVal = Math.floor(Math.round(fToValue * LCD));
            // Work out full inches 
            iVal2 = Math.floor(iVal / LCD);

            // $^^ If number of inches is 12, increment the number of feet and set inches to zero
            if (iVal2 == 12) {
                iFeet++;
                iVal2 = 0;
                iVal = 0;
            }

            // Even if it's zero, always say it
            if (iFeet == 1) {
                sReturnString += iFeet + sFEET_SINGLE_INDICATOR;
            }
            else {
                sReturnString += iFeet + sFEET_PLURAL_INDICATOR;
            }

            // If there are no full inches, only give the fractional amont
            // e.g. 1 1/2 and 0 3/4 should display as "1 1/2 inches" and "3/4 inch"
            if (iVal2 == 0) {
                sInches = '';
            }
            else {
                sInches = iVal2 + '';
            }
            // and remaining whole number which is the fraction we're after expressed as a number of LCDs
            iValNumerator = Math.floor(iVal) - (iVal2 * LCD);


            // If there's any fractional part left, reduce the fraction to its simplest form
            sInchesFractionFinal = '';

            if (iValNumerator == 0) {
                sInchesFraction = '';
            }
            else {
                // otherwise there's a fractional part left. Reduce the fraction to its simplest form
                bContinue = true;
                iValDenominator = LCD;
                while (bContinue == true) {
                    iVal = Math.floor(iValNumerator / 2);
                    iVal2 = Math.floor(iValDenominator / 2);
                    //                        if ((iVal != Math.Floor((double)iVal)) || (iVal2 != Math.Floor((double)iVal2))) {
                    if (((iVal * 2) != iValNumerator) || ((iVal2 * 2) != iValDenominator)) {
                        bContinue = false;
                    } else {
                        iValNumerator = iVal;
                        iValDenominator = iVal2;
                    }
                }
                // Create the strings
                if (sInches == '') {
                    //                    if (bReadingPunctuation) {
                    //                        sInchesFraction += "and ";
                    //                    } else {
                    //                        sInchesFraction += " ";
                    //                    }
                    sInchesFraction = iValNumerator + "/" + iValDenominator;
                } else {
                    sInchesFraction = iValNumerator + "/" + iValDenominator;
                }
                // Enclose the fraction in a span to allow a smaller font to be rendered via CSS
                sInchesFractionFinal = " " + sInchesFraction;
            }
            // Add the inches to the final return string
            if ((sInches != '') || (sInchesFraction != '')) {
                // Add the value
                if (bReadingPunctuation) {
                    sReturnString += ",";
                }
                // Add them separately to avoide a double space in certain circmstances
                if (sInches != '') {
                    sReturnString += " " + sInches;
                }
                // If there's a fractional part, add the fraction together within a classed HTML SPAN 
                // element to enable CSS styling on the fraction.
                if (sInchesFraction != '') {
                    sReturnString += " " + sInchesFractionFinal;
                }

                // Decide whether it's singular or plural inches
                if ((sInches == '') || ((sInches == "1") && (sInchesFraction == ''))) {
                    sReturnString += sINCHES_SINGLE_INDICATOR;
                } else {
                    sReturnString += sINCHES_PLURAL_INDICATOR;
                }
            }
        }

        // Return the result
        return sReturnString;
    }
    catch (err) {
        return "-" + err.message;
    }

}

function ToolTipDate(dateString) {

    var day = '';
    var theDate = new Date(Date.parse(dateString));
    switch (theDate.getDay()) {
        case 0:
            day = "Sun";
            break;
        case 1:
            day = "Mon";
            break;
        case 2:
            day = "Tue";
            break;
        case 3:
            day = "Wed";
            break;
        case 4:
            day = "Thu";
            break;
        case 5:
            day = "Fri";
            break;
        case 6:
            day = "Sat";
    }

    day +=
        ' ' +
        dateString.substr(8, 2) +
        '/' +
        dateString.substr(5, 2) +
        '/' +
        dateString.substr(0, 4) +
        ' @ ' +
        dateString.substr(11, 5);
    return day;
}