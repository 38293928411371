$(document).ready(function () {

    //    var iCurrentStatsEntity;
    //    var bLabellingAllRivers = false;
    //    var bLabellingSelected = true;

    String.prototype.trim = function () { return this.replace(/^\s+|\s+$/g, ''); };
    //alert("TRIM IMPLEMENTED");

    window.scrollTo(0, location.hash);
    // alert ("Scrolling to " + location.hash);
    jQuery("abbr.timeago").timeago();
    $.cookie();

    // *******************************************************************
    // ***** River Entry Favourite/Unfavourite IMAGE Click Function ****** 
    // *******************************************************************
    $(document).on('mousedown', '.river_entry img', function () {
        var thisElement = $(this);

        console.log("'.river_entry img').on('click'", thisElement);
        console.log("Got Here");
        //console.log (" Parents 2 is ",$(this).parents().eq(2).attr('tagName'));
        //        console.log (this);
        //        console.log ($(this));
        //        console.log ("this.tagName ", this.tagName);
        //        console.log ("$(this).tagName ", $(this).get().tagName);

        // Extract the bracketed Entity Mnemonic from the previous (<span>) sibling
        // Note this will only work if the click item is the FavouriteMe or UnFavouriteMe image
        // but we call it here as we only want to call the routine once.
        // Try/Catch logic handles any failure

        //        var thisMnemonic = StringExtractDelimitedSubstring(thisElement.prev().text(), ANGLE_BRACKET_LEFT, ANGLE_BRACKET_RIGHT);
        //$(this).parent().next().children('.entry_name').text()

        var entryDiv = thisElement.parent().next().children('.entry_name');
        var thisMnemonic = entryDiv.text();
        console.log("*************** Extracted ", thisMnemonic);
        //var thisLocation;
        //var locationDiv = entryDiv.next().children().children('.location_name');
        //var locDiv2 = thisElement.parent().next().children().next().children().children('.location_name');
        //var thisLoc2 = locDiv2.text();
        var thisLocation = $(this).parent().next().children('.entry_name').next().children().children('.location_name').text()
        if (!thisLocation) {
            thisLocation = '';
        }
        console.log("*************** Extracted ", thisLocation);

        if (thisElement.hasClass("FavouriteMe"))
        // * Normal entry "FavouriteMe" image has been clicked
        {
            //console.log ("FavouriteMe image clicked >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
            //console.log ("*************** Previous sibling is ", $(this).prev().text());

            thisElement.addClass("UnFavouriteMe");
            thisElement.removeClass("FavouriteMe");
            thisElement.attr('src', 'images/UFM.png');


            // Add the Entity to the EntityFavourite Cookie value
            sEntityFave = CookieAddValue(COOKIE_ENTITY_FAVE, sEntityFave, thisMnemonic + "$" + thisLocation);

        }
        else if (thisElement.hasClass("UnFavouriteMe"))
        // * Favourite entry "UnFavouriteMe" image has been clicked
        {
            thisElement.addClass("FavouriteMe");
            thisElement.removeClass("UnFavouriteMe");
            thisElement.attr('src', 'images/FM.png');

            // Remove the Entity from the EntityFavourite Cookie value
            sEntityFave = CookieRemoveValue(COOKIE_ENTITY_FAVE, sEntityFave, thisMnemonic + "$" + thisLocation);
        }

        SetFavouritesButtonVisibility();

        FavouritesSetupAndChange();
        // Prevent event bubbling
        return false;

    });

    // *******************************************************************
    // ***** River Entry Link Click Function ****** 
    // *******************************************************************
    $(document).on('mousedown', '.river_entry_link', function () {
        var thisName;
        var thisElement = $(this);
        console.log("River Entry Link click", thisElement);

        //Get the ID of the clicked item
        var $liItemIDAttr = thisElement.attr('id');
        //console.log ("River Entry Link clicked ID attribute is ", $liItemIDAttr);
        // Extract the Entity number from the end of the IDe.g. from <. . . .  "id="fav_rli109" or "id="rli45">
        var $liItemIDIndexStart = $liItemIDAttr.indexOf('rli');
        var $liItemID = $liItemIDAttr.substr($liItemIDIndexStart + 3);

        //console.log ("Clicked ID is", $liItemID);
        EntityMouseover($liItemID);
        // Display the default graph type 1
        if (SHOW_STATS == 1) {
            ShowDefaultGraph();
        }
        if (AUTO_MAP == 1) {
            SetLocalMapCentreAndZoom($liItemID);
        }
        iCurrentStatsEntity = $liItemID;
        $.cookie(COOKIE_ENTITY_LAST_SELECT, iCurrentStatsEntity.toString(), COOKIE_DO_NOT_EXPIRE);

        setTitleAndLinks();
        //        setFBUrl("http://www.rivermonitor.co.uk/help.aspx");

        //        thisName = sReferenceData[iCurrentStatsEntity]['Name'];
        //        document.title = thisName + " River Levels";

        //        updateTwitterValues("http://www.rivermonitor.co.uk/" + thisName, document.title)
        //        //updateTwitterValues(share_url, title)

        //$('#twitter-share-button').attr("data-url", "http://www.rivermonitor.co.uk/" + thisName);

        $('#RListDiv').hide();
        $('#inputSearch').val('');

        return false;
    });


    //    $('#unitsButton').bind('click', function () {
    $(document).on('click', '#unitsButton', function () {
        var thisElement = $(this);
        console.log("Units Button click", thisElement);
        console.log("UnitsBUtton clicked with current graph = ", iCurrentGraph);
        ToggleUnits();
        ShowEntityStats(iCurrentStatsEntity, true);

        console.log("UnitsBUtton clicked and graph just displayed with graph = ", iCurrentGraph);
        return false;
    });

    //
    // TODO: Add generic mouseover logic for Stats Entry to highlight RS Marker
    //

    //    $('[id^="stats_row"]').live('mouseover', function () {
    $(document).on('mouseover', '[id^="stats_row"]', function () {
        var thisElement = $(this);
        console.log("RS Stats entry mouseover", thisElement);

        //Get the ID of the clicked item
        var $trItemIDAttr = thisElement.attr('id');
        //console.log ("Stats Entry Link clicked ID attribute is ", $trItemIDAttr);
        // Extract the RS number from the end of the IDe.g. from <. . . .  "id="stats_row_bot__3" or "id="stats_row_bot__12">
        var $trItemIDIndexStart = $trItemIDAttr.indexOf('__');
        var $trItemID = $trItemIDAttr.substr($trItemIDIndexStart + 2);

        //console.log ("Clicked Stats Row ID is", $trItemID);
        RSFocus(iCurrentStatsEntity, $trItemID, true);
    });

    // ***************************************************************
    // ***** Show Favourites Only / Show All link Click Function ***** 
    // ***************************************************************
    //    $("#FavouritesOnly").bind('click', function () {
    //TODO:$$$ DELETE this event - now based on the Search Input box
    //$(document).on('click', "#FavouritesOnly", function () {
    //    ToggleFavourite($(this));
    //});


    //    $("#QFavouritesOnly").bind('click', function () {
    $(document).on('click', "#QFavouritesOnly", function () {
        var thisElement = $(this);
        console.log("Favourites Only click", thisElement);

        if (thisElement.hasClass("ShowAll")) {
            console.log("Doing Show All");
            // Currently showing Favourites only, now Show All
            $(thisElement).removeClass("FavouritesOnly");
            $('#RList a').slideDown("slow");
            $('#FavouritesOnly').text('Favourites');
            $('#FavouritesOnly').removeClass('ShowAll');
            $('#FavouritesOnly').addClass('FavesOnly');
        }
        else {
            // Currently showing all, now Show Favourites only
            console.log("Doing Faves Only");
            $(thisElement).addClass("FavouritesOnly");
            $('#FavouritesOnly').text('Show All');
            $('#FavouritesOnly').removeClass('FavesOnly');
            $('#FavouritesOnly').addClass('ShowAll');
            console.log("The parent is ", $('#RList a img.FavouriteMe').parents().eq(2));
            //                $('#RList a img.FavouriteMe').parents().eq(2).slideUp();
            $('#RList a img.FavouriteMe').each(function () {
                $(this).parents().eq(2).slideUp("slow");
            });
            //                $('#FavouritesOnly a').text('Show All Rivers\<img alt="" src="images/FavouriteMe.PNG" /\>');
        }
        return false;
    });


    // Create new Entity List Dropdown of Entities containing the given search string
    function FilterEntities(useSearch) {
        var newDropdownHTML = "";
        var addDiv = true;
        var divOffset, entityStartOffset, entityEndOffset, nextDiv, thisEntity, searchTitle, isAFavourite;

        upTo = 0;
        divOffset = 0;
        nextDiv = 0;
        console.log("Starting");
        var search = $('#inputSearch').val().trim().toUpperCase();
        nextDiv = 0;
        console.log("Searching [" + search + "]");

        nextDiv = 0;
        if (useSearch && (search.length <= 1)) {
            //            $('#RList').html(fullEntityList);
            //            $('#RListDropdown').html(fullEntityList);
            //            $('#RListHeadCont').html(fullEntityList);
            $('#RListDiv').hide();
        } else {
            if (useSearch) {

                // ***********************
                if (workerSearch != undefined) {
                    //workerFave.terminate();
                }
                else {
                    workerSearch = new Worker('..\\js\\workerSearch.js');
                    workerSearch.addEventListener('message',
                        function (event) {
                            TimedConsoleLog("Back with that Search " + event.data.length);
                            searchTitle = (event.data.length == 0 ? "No Matches" : "Search Matches");
                            $('#RListHeadCont').html(event.data);
                        });
                    workerSearch.addEventListener('error', function (event) {
                        TimedConsoleLog("*************** FAILED with those Searches");
                        console.error('error received from workerSearch => ', event);
                    });
                }
                TimedConsoleLog("Posting to worker");
                workerSearch.postMessage({ entityList: fullEntityList, entitySearch: search, entityCookie: $.cookie(COOKIE_ENTITY_FAVE) });
                TimedConsoleLog("Back from posting");


                // ******************************


                // Fill the dropdown list based on Entity search criteria
                // CODE WOZ ERE

            }
            else {
                // Fill the dropdown with Favourites
                searchTitle = "Favourites";
                $('#RListHeadCont').html(FavouritesHTML);
                $('#inputSearch').val('');
            }

            $('#RListHead').html(searchTitle);

            //            $('#RList').html(newDropdownHTML);
            //            $('#RListDropdown').html(newDropdownHTML);
            $('#RListDiv').show();
        }

        console.log("Ending");
    }

    function SearchSetupAndChange() {


    }

    function FavouritesSetupAndChange() {
        if (workerFave != undefined) {
            //workerFave.terminate();
        }
        else {
            workerFave = new Worker('..\\js\\workerFavourites.js');
            workerFave.addEventListener('message',
                function (event) {
                    TimedConsoleLog("Back with those Favourites");
                    FavouritesHTML = event.data;
                });
            workerFave.addEventListener('error', function (event) {
                TimedConsoleLog("*************** FAILED with those Favourites");
                console.error('error received from workerFave => ', event);
            });
        }
        TimedConsoleLog("Posting to worker");
        workerFave.postMessage({ entityList: fullEntityList, entityCookie: $.cookie(COOKIE_ENTITY_FAVE) });
        TimedConsoleLog("Back from posting");
    }

    function SetFavouritesButtonVisibility() {
        let cookieFave = $.cookie(COOKIE_ENTITY_FAVE);
        if ((cookieFave != null) && (cookieFave.length > 0)) {
            $('#btnFavourites').prop('disabled', false);
        }
        else {
            $('#btnFavourites').prop('disabled', true);
        }
    }


    //*******************************************

    function DrawSampleChart() {
        console.log("*********************** DRAWING THE GRAPH");
        var ctx = document.getElementById('chartCanvas');
        var myChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
                datasets: [{
                    label: '# of Votes',
                    data: [12, 19, 3, 5, 2, 3],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });
        console.log("*********************** DONE DRAWIN");

    }

    // ***************************************************************
    // ***** Show Favourites Only / Show All link Click Function ***** 
    // ***************************************************************
    //    $("#RunTest").bind('click', function () {
    $(document).on('click', "#RunTest", function () {
        // Show all Entity and RS markers
        TryThis(42);
    });

    //    $("#MarkersAll").bind('click', function () {
    $(document).on('click', "#MarkersAll", function () {
        // Show all Entity and RS markers
        ShowHideEntities(true, true);
        return false;
    });

    //    $("#MarkersNone").bind('click', function () {
    $(document).on('click', "#MarkersNone", function () {
        // Show all Entity and RS markers
        ShowHideEntities(false, false);
        return false;
    });

    //    $("#MarkersSelected").bind('click', function () {
    $(document).on('click', "#MarkersSelected", function () {
        // Show all Entity and RS markers
        ShowHideEntities(false, true);
        return false;
    });

    // ^^ Delete the below once done
    //    $("#MarkersToggle").bind('click', function () {
    $(document).on('click', "#MarkersToggle", function () {
        var thisElement = $(this);

        if (thisElement.hasClass("LabelAll")) {
            console.log("Doing Show All");
            // Currently showing only the Entity with the focus, now Show All
            ShowHideEntities(true);
            //$('#MarkersToggle a').text('Label Selected River');
            $(thisElement).removeClass('LabelAll');
        }
        else {
            // Currently showing all, now Show Favourites only
            console.log("Doing Faves Only");
            ShowHideEntities(false);
            //$('#MarkersToggle a').text('Label All Rivers');
            $(thisElement).addClass('LabelAll');
        }
        return false;
    });

    // ***** Set Default Map Position
    //    $("#SetDefaultMap").bind('click', function () {
    $(document).on('click', "#SetDefaultMap", function () {
        SaveMapCentreAndZoom();
    });

    // ***** Show Default Map Position
    //    $("#ShowDefaultMap").bind('click', function () {
    $(document).on('click', "#ShowDefaultMap", function () {
        SetMapCentreAndZoomToDefault(false);
    });

    $("#menu li").hover(function () {
        console.log("menu_li hover");
        $(this).find("ul").show("fast");
    }, function () {
        $(this).find("ul").hide("fast");
    });

    //    $(".mapButton").bind('click', function () {
    $(document).on('click', ".mapButton", function () {

        var iGraphType;
        console.log("mapButton live click");
        var thisElement = $(this);

        if (thisElement.attr("id") == "G1") { iGraphType = 1; }
        else if (thisElement.attr("id") == "G2") { iGraphType = 2; }

        // This routine appears to be triggered when the Favourites / Show All button is hit
        // and therefore tries to redisplay the current graph but $(this) is not the G1/G2 Span element
        // hence iGraphType remains undefined. The Graph fails to display and no image is then displayed
        if (iGraphType != undefined) {
            // Display the graph
            ShowGraph(iGraphType);

            // Update the cookie
            $.cookie(COOKIE_DG, iGraphType.toString(), COOKIE_DO_NOT_EXPIRE);
        }
        return false;
    });

    SetFavouritesButtonVisibility();

    SetGraphLegendClickHandler();

    $(document).on('click', "#btnFavourites", function () {
        if ($('#RListDiv').is(':visible')) {
            $('#RListDiv').hide();
        } else {
            FilterEntities(false);
        }
    });

    $(document).on('keyup', "#inputSearch", function () {

        clearTimeout(searchTimer);
        searchTimer = setTimeout(function () { FilterEntities(true); }, Consts.sENTITY_SEARCH_START_MS);
    });


    $(document).on('blur', "#inputSearch", function () {
        $('#inputSearch').val('');
        FilterEntities(true);
    });

    $(document).on('blur', "#btnFavourites", function () {
        $('#inputSearch').val('');
        FilterEntities(true);
    });

    // ******************************************
    // ************ Main Entry Point ************
    // ******************************************

    console.log("Into the code");

    //var fullEntityList = $("#RList").val();
    var fullEntityList = $("#master_CPHbody_txtRiverList").val();

    // Highlight the host page in the navigation menu
    //TODO:$$$ To remove?
    $("#navRiverMap").addClass('menu_highlight');

    $(".RiversAdImg1 img").attr("src", $('#master_CPHbody_txtGRP1DataFile').val());
    $(".RiversAdImg2 img").attr("src", $('#master_CPHbody_txtGRP2DataFile').val());

    //    $("a#popupClose").live('click', function() {
    $(document).on('click', "a#popupClose", function () {
        $('div#pop-upHelp').hide();
    });
    //    $("a#popupCloseCookies").live('click', function() {
    $(document).on('click', "a#popupCloseCookies", function () {
        $('div#pop-upCookies').hide();
    });

    //TODO:Take me out
    //FilterEntities();
    //DrawSampleChart();
    //DrawMHRLChart();
    //myBarChart = new Chart(ctx, {
    //    type: 'line',
    //    data: svrData,
    //    options: options
    //});

    var canvas = document.getElementById('chartCanvas');
    var ctx = canvas.getContext('2d');
    SetGraphDefaults();
    myBarChart = new Chart(ctx,
        {
            type: 'line',
            options: graphOptions
        });


    var dialogOpts = {
        autoopen: true,
        modal: true
    };

    //    $('#dlgCookies').dialog(dialogOpts);

    if (MAPS_ON == 1) {
        //+_+
        if (GOOGLE_ON == 1) {
            mapLatlng = new google.maps.LatLng(52.696361078274485, -2.757568359375); // Shrewsbury, England
            mapOptions = {
                "streetViewControl": false,
                "scrollwheel": false,
                "zoom": 5,
                "center": mapLatlng,
                "mapTypeId": google.maps.MapTypeId.HYBRID
            };
        }
        //+_+
        else if (OS_ON == 1) {
            //+_+

            //+_+
            console.log("Var Mapping");

            layerListeners = {
                featureclick: function (e) {
                    OSlogit(e.object.name + " layerlisterner says individually: " + e.feature.id + " clicked.");
                    return false;
                },
                nofeatureclick: function (e) {
                    OSlogit(e.object.name + " layerlisterner says individually: No feature clicked.");
                }
            };

            Entitystyle = new OpenLayers.StyleMap({
                'default': OpenLayers.Util.applyDefaults(
                    { "label": "${lbl}", "pointRadius": 10 },
                    OpenLayers.Feature.Vector.style["default"]),
                'select': OpenLayers.Util.applyDefaults(
                    { "pointRadius": 15 },
                    OpenLayers.Feature.Vector.style.select)
            });
            RSstyle = new OpenLayers.StyleMap({
                'default': OpenLayers.Util.applyDefaults(
                    { "label": "${lbl}", "pointRadius": 30 },
                    OpenLayers.Feature.Vector.style["default"]),
                'select': OpenLayers.Util.applyDefaults(
                    { "pointRadius": 50 },
                    OpenLayers.Feature.Vector.style.select)
            });
            Polystyle = new OpenLayers.StyleMap({
                'select': OpenLayers.Util.applyDefaults(
                    { "pointRadius": 50 },
                    OpenLayers.Feature.Vector.style.select)
            });

            EntityLayer = new OpenLayers.Layer.Vector("River Mkrs", {
                "styleMap": Entitystyle,
                "visibility": false,
                "eventListeners": layerListeners
            });

            //$^$ Check whether the separate layer is needed?
            selEntityLayer = new OpenLayers.Layer.Vector("Selected River Mkr", {
                "styleMap": Entitystyle,
                "visibility": true,
                "eventListeners": layerListeners
            });

            RSLayer = new OpenLayers.Layer.Markers("Gauge Mkrs", {});

            //            styleMap: RSstyle,
            //            eventListeners: layerListeners
            //        });

            VectorLayer = new OpenLayers.Layer.Vector("River Courses", {
                "styleMap": Polystyle,
                "eventListeners": layerListeners
            });

            function log(msg) {
                console.log(msg);
            }

            console.log("Mapping mappoint");
            //            mapLatlng = new OpenLayers.LonLat(290293, 751020); // Aberfeldy
            console.log("Mapping options");
            mapOptionsOld = { "resolutions": [2500, 1000, 500, 200, 100, 50, 25, 10, 5, 4, 2.5, 2, 1] };
            //            mapOptions = { resolutions: [5, 4, 2.5, 2, 1] };
            /*            console.log("Mapping layer");
            vectorLayer = new OpenLayers.Layer.Vector("Vector Layer");
            markerLayer = new OpenLayers.Layer.Markers("Marker Layer"); */
            //markerLayer = OpenLayers.Layer.Markers();   // +__+

            //+_+

        }
    }

    console.log("River map created");
    console.log (ConvertToUnits(.334,
        "M",
        "F",
        8));

    var objzIndex = { zIndex: 0 };
    var iNextInt = 10000;
    objzIndex.zIndex = iNextInt;

    //ShowCookieNote();
    ShowHelp();

    if (MAPS_ON == 1) {
        if (GOOGLE_ON == 1) {
            map = new google.maps.Map(document.getElementById("map_canvas"), mapOptions);
        }
        //+_+
        else if (OS_ON == 1) {

            //+_+

            console.log("Map being created");

            //            var map, layer,
            key = "E59F31060C673345E0430B6CA40A0D79",
                OSHQ = {
                    WGS84: [50.936715, -1.4701989],
                    OSGB: [437324, 115386]
                },
                zoom = 5;

            /* $$$ */
            //                map = new OpenLayers.Map('map_canvas');

            layer = new OpenLayers.Layer.OSOpenSpace("OS Openspace", key, {});

            //                map.addLayer(layer);

            //                map.setCenter(new OpenLayers.LonLat(OSHQ.OSGB), zoom);
            //                map.addControl(new OpenLayers.Control.LayerSwitcher());
            /* $$$ */



            map = new OpenLayers.Map("map_canvas", {
                "resolutions": [2500, 1000, 500, 200, 100, 50, 25, 10, 5, 4, 2.5, 2, 1],
                "div": "map",
                "allOverlays": true,
                "layers": [layer, EntityLayer, RSLayer, VectorLayer],
                "zoom": 2,
                "center": new OpenLayers.LonLat(OSHQ.OSGB),
                "eventListeners": {
                    "featureover": function (e) {
                        console.log("Entered FIRED");
                        //                        alert("Entered " + e.feature.geometry.attributes.EntityID);
                        e.feature.renderIntent = "select";
                        e.feature.layer.drawFeature(e.feature);
                        OSlogit("Map says: Pointer entered " + e.feature.id + " on " + e.feature.layer["name"]);
                        EntityMouseover(e.feature.attributes["EntityID"]);
                        // Display the default graph type 1
                        if (SHOW_STATS == 1) {
                            ShowDefaultGraph();
                        }
                    },
                    "featureout": function (e) {
                        console.log("Out FIRED");
                        //                        alert("Out " + e.feature.geometry.attributes.EntityID);
                        e.feature.renderIntent = "default";
                        e.feature.layer.drawFeature(e.feature);
                        OSlogit("Map says: Pointer left " + e.feature.id + " on " + e.feature.layer["name"]);
                    },
                    "featureclick": function (e) {
                        console.log("Click FIRED");
                        //                        alert("Clicked " + e.feature.geometry.attributes.EntityID);
                        console.log("Click FIRED");
                        OSlogit("Map says: " + e.feature.id + " clicked on " + e.feature.layer["name"]);
                    }
                }
            });



            function OSlogit(msg) {
                console.log(msg);
            }
            console.log("Setting centre");
            //            map.setCenter(new OpenLayers.LonLat(mapLatlng), 1);
            //            map.addLayer(VectorLayer);
            console.log("Layer adding");
            //            map.addLayer(EntityLayer);
            map.addLayer(layer);
            //map.addControl(new OpenLayers.Control.LayerSwitcher());
            //            map.addControl(new OpenLayers.Control.LayerSwitcher());
            //+_+

            map.events.register('zoomend', this, function (event) {
                var zoom = map.getZoom();
                console.log("Detected Zoom change " + zoom);
                if (zoom > 5) {
                    SetPolyWidths(10);
                }
                else if (zoom > 3) {
                    SetPolyWidths(8);
                }
                else if (zoom > 2) {
                    SetPolyWidths(6);
                }
                else {
                    SetPolyWidths(2);
                    //                    VectorLayer.styleMap.styles.select.defaultStyle.strokeWidth = 2; // 
                    //                    VectorLayer.styleMap.styles.default.defaultStyle.strokeWidth = 2; // 
                }
                VectorLayer.redraw();
            });

        }
        SetMapCentreAndZoomToDefault(false);
    }
    try {
        console.log("Getting Ref Data");
        //alert("Getting Ref Data");
        console.log("TestButtonCheck");

        if ($.cookie("MHRLTestButton") != "ON") {
            $('#RunTest').hide();
        }

        console.log("Determine Ref Filename");
        var filenameRef = $('#master_CPHbody_txtJSONRefFile').val();
        console.log(filenameRef);

        // Load in and assign the Reference Data JSON string    
        //$.getJSON('RefData/RLReferenceData_111.json?c=1', function (data) {
        $.getJSON(filenameRef, function (data) {
            console.log("Ref Data here");
            //alert("Ref Data here");
            sReferenceData = data;

            var favTrigger = new Object();
            var iDefaultEntry = -1;                 // Default Entry to display stats for
            var bRiverLocationMatch = false;
            var bRiverMatch = false;
            var DfltMne = $('#master_CPHbody_txtDfltMne').val().toUpperCase();
            var DfltLoc = $('#master_CPHbody_txtDfltLoc').val().toUpperCase();
            console.log("************** Dflt Mne ", DfltMne);

            // Create the entity list with appropriate graphics and populate the Favourites
            // section based on the contents of the cookie
            $.each(data, function (entryIndex, entry) {
                // Check whether the Entity Mnemonic exists within the EntityFave Cookie string.
                // If so, trigger the "FavouriteMe" click event 
                //^^ CHECK THIS! Should just change the image
                if (CookieContainsDelimitedValue(COOKIE_ENTITY_FAVE, entry['Name'])) {
                    favTrigger[entry['Name']] = '#rli' + entryIndex + ' .FavouriteMe';
                    //                        //console.log ("Added ", favTrigger[entry['__Mne']]);
                }
                //                    console.log ("Made it to the end of Entity List $.each loop");

                //console.log ("!!!! Dflt test ", DfltMne, " : ", entry['Name']);


                //console.log("Dfltmne/Loc is [" + DfltMne + "/" + DfltLoc + "] this name is [" + entry['Name'] + "/" + entry['Loc']);
                //console.log("bRiverLocationMatch / bRiverMatch is [" + bRiverLocationMatch.toString() + "/" + bRiverMatch);
                if (!bRiverLocationMatch) {
                    try {
                        if ((DfltMne == entry['Name'].toUpperCase()) && (DfltLoc == entry['Loc'].toUpperCase())) {
                            iDefaultEntry = entryIndex;
                            bRiverLocationMatch = true;
                        }
                    }
                    catch (RLerr) { }

                    if ((!bRiverMatch) && (!bRiverLocationMatch) && (DfltMne == entry['Name'].toUpperCase())) {
                        iDefaultEntry = entryIndex;
                        bRiverMatch = true;
                    }
                }
            });

            console.log("Getting Static Data");

            var filenameDyn = $('#master_CPHbody_txtJSONDynFile').val();
            // Load in and process the Dynamic Data JSON string    
            //$.getJSON('RefData/RLDynamicData_111.json?c=1', function (data) {
            $.getJSON(filenameDyn, function (data) {
                var html, sFaveWorkList, sNextFave;
                var thisColourVal;
                console.log("About to parse");

                sDynamicData = data;
                // Create the Entity Markers, RS Markers and Polylines on the Map canvas
                DisplayPolyLines();

                console.log("About to retrieve the Fave coookie");
                // Retrieve the various cookie values : list of favourites, last selected entity,
                // Default Units
                sEntityFave = EmptyStringIfNull($.cookie(COOKIE_ENTITY_FAVE));
                iEntityLastSelect = ZeroIfNull($.cookie(COOKIE_ENTITY_LAST_SELECT));
                iCurrentGraph = ZeroIfNull($.cookie(COOKIE_DG));

                SetUnits(true);

                // ^^ Original locatin of favourite settings from cookie using entry['__Mne']


                console.log("favtrigger start");
                sFaveWorkList = sEntityFave;

                while (sFaveWorkList != "") {
                    sNextFave = GetNextDelimitedValue(sFaveWorkList, Consts.sDELIMITER_BAR);
                    console.log("Next Fave is ", sNextFave);

                    MarkFavourite(favTrigger[sNextFave]);
                    //                    $(favTrigger[sNextFave]).trigger('click');
                    sFaveWorkList = sFaveWorkList.substr(sNextFave.length + 1);
                    // If the default entry to display hasn't yet been set, set it.
                    // This will therefore be the top entry in the Favourites list
                    if ((iDefaultEntry == -1) && (bFavouritesOn == true)) {
                        // Extract the number from the ID / Imagename combo. e.g. "#rli4 .FavouriteMe"
                        //                        iDefaultEntry = favTrigger[sNextFave].substr ("#rli".length, 
                        //                            favTrigger[sNextFave].indexOf (Consts.sSPACE));

                        //                        try {
                        //                            iDefaultEntry = favTrigger[sNextFave].substr(Consts.iRIVER_LINK_LEN,
                        //                            favTrigger[sNextFave].indexOf(Consts.sSPACE) - Consts.iRIVER_LINK_LEN);
                        //                            console.log ("Default ENtry SET AT ____________________ ", iDefaultEntry);
                        //                        }
                        //                        catch (exception) {
                        //                        }
                    }
                }

                // If the default entry to display hasn't yet been set (i.e. there
                // are no favourites), set it to the last river selected, or if that isn't
                // defined, to the first Entry in the list
                // ^^ Alternatively, the "River of the Day"  - future enhancement maybe
                if (iDefaultEntry == -1) {
                    if (iEntityLastSelect != null) {
                        iDefaultEntry = iEntityLastSelect;
                        console.log("Default ENtry DEFAULTS TO LAST SELeCTED +++++++++++++ ", iEntityLastSelect);
                    }
                    else {
                        // No favourite or link set-up so default to the first in the list
                        iDefaultEntry = 0;
                        console.log("Default ENtry DEFAULTS TO ____________________ 0");
                    }
                }

                SetLocalMapCentreAndZoom(iDefaultEntry)


                // And now trigger a mouseover to display stats for the default entry and display the default graph
                EntityMouseover(iDefaultEntry);

                if (SHOW_STATS == 1) {
                    ShowDefaultGraph();
                }

                iCurrentStatsEntity = iDefaultEntry;
                setTitleAndLinks();

                //TODO: ^^^ This can run earlier
                FavouritesSetupAndChange();

            });
            console.log("Completed inner");
        });
        console.log("Completed outer");
    }
    catch (exception) {
        //alert(exception.description)
    }
    finally {

        try {
            //            $("#RiverSection").niceScroll("#RList", { autohidemode: false, cursorcolor: "#00FF00" });
            //TODO:$$$ Reinstate?
            //            $("#specific_stats").niceScroll({ autohidemode: false, cursorcolor: "#00FF00" });
        }
        catch (err4) { }

        bStartupComplete = true;
    }

});

function DoSaveMapCentreAndZoom() {
    SaveMapCentreAndZoom(map);
}

var debugging = true; // or true
if (typeof console == "undefined") var console = { log: function () { } };
else if (!debugging || typeof console.log == "undefined") console.log = function () { };

//<script type="text/javascript"> 
//$(document).ready(function() {

//    console.log ("Time Ago ", timeAgo(document.getElementById("txtReadingDateTime"),document.getElementById("txtCreateDateTime")));
//    jQuery("abbr.timeago").timeago();
//});
